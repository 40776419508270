import React, { useState, useContext, useEffect } from 'react';

import en from './locale/en.json';
import de from './locale/de.json';
import es from './locale/es.json';
import ca from './locale/ca.json';
import ptBr from './locale/pt_BR.json';

const getLanguageFromURL = () => {
  const lang = window.location.pathname;
  if (lang.startsWith('/de/') || (lang.startsWith('/de') && window.location.pathname.length === 3)) return 'de';
  if (lang.startsWith('/es/') || (lang.startsWith('/es') && window.location.pathname.length === 3)) return 'es';
  if (lang.startsWith('/ca/') || (lang.startsWith('/ca') && window.location.pathname.length === 3)) return 'ca';
  if (lang.startsWith('/pt-br/') || (lang.startsWith('/pt-br') && window.location.pathname.length === 27)) return 'pt-br';
  return 'en';
};

class I18n {
  constructor(lang) {
    if (lang) {
      this.getTranslation(lang);
    } else {
      this.initialLanguage();
      this.getTranslation(this.lang);
    }
    this.fallbackTranslation = en;
  }

  initialLanguage() {
    const lang = getLanguageFromURL();
    this.lang = lang
  }

  getLanguage() {
    return this.lang;
  }

  changeLanguage(lang) {
    this.lang = lang;
    this.getTranslation(lang);

    if (lang === 'ca') {
      this.translationObject = ca;
      this.lang = 'ca';
      return;
    }
    if (lang === 'pt-br') {
      this.translationObject = ptBr;
      this.lang = 'pt-br';
      return;
    }
  }

  getTranslation(lang) {
    if (lang === 'de') {
      this.translationObject = de;
      this.lang = 'de';
      return;
    }
    if (lang === 'es') {
      this.translationObject = es;
      this.lang = 'es';
      return;
    }
    if (lang === 'pt-br') {
      this.translationObject = ptBr;
      this.lang = 'pt-br';
      return;
    }
    this.translationObject = en;
    this.lang = 'en';
  }

  t(key, options = null) {
    const keys = key.split('.');
    let text = '';
    let tmpObj = this.translationObject;
    for (let i = 0; i < keys.length; i += 1) {
      if (tmpObj && tmpObj[keys[i]]) {
        tmpObj = tmpObj[keys[i]];
      } else {
        tmpObj = undefined;
      }
    }
    if (tmpObj) {
      text = tmpObj;
    }
    if (!text) {
      tmpObj = this.fallbackTranslation;
      for (let i = 0; i < keys.length; i += 1) {
        if (tmpObj && tmpObj[keys[i]]) {
          tmpObj = tmpObj[keys[i]];
        } else {
          tmpObj = undefined;
        }
      }
    }
    if (tmpObj) {
      text = tmpObj;
    }
    if (text && options && typeof options === 'object') {
      Object.keys(options).forEach((attr) => {
        const reg = new RegExp(`{{${attr}}}`, 'g');
        text = text.replace(reg, options[attr]);
      });
    }
    return text;
  }
}
// cancel that until we make again change language available
// const tmp = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
export const i18n = new I18n();
const initialState = { i18n };

export const I18nContext = React.createContext(initialState);
export const I18nContextProvider = ({ children }) => {
  const [lang, setLang] = useState(i18n.getLanguage());
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <I18nContext.Provider value={{
      i18n, lang, setLang,
    }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export const useTranslation = () => {
  const translation = useContext(I18nContext).i18n;
  return translation;
};

export const useI18n = () => {
  const translation = useContext(I18nContext);
  return translation;
};
