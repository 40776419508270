import React from 'react';
import styled from '@emotion/styled';
import {
  faAndroid,
  faApple, faChrome, faEdge, faFirefox, faInternetExplorer, faSafari, faWindows,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons';
import { Computer, getDeviceSurveyStatus, Mobile } from '../helper';
import { useTranslation } from '../../i18n';
import DeviceSecurityStatus from './DeviceSecurityStatus';

const iconOS = {
  macOS: faApple,
  iOS: faApple,
  windows: faWindows,
  android: faAndroid,
};

const iconBrowsers = {
  chrome: faChrome,
  firefox: faFirefox,
  safari: faSafari,
  IE: faInternetExplorer,
  edge: faEdge,
  other: faQuestionCircle,
};

const DeviceCard:React.FC<{
  device: Computer|Mobile, onClick:() => void, autoDetect?: boolean
}> = ({ device, onClick, autoDetect }) => {
  const i18n = useTranslation();
  if (!device) return null;
  if (autoDetect) {
    return (
      <DeviceCardWrapper status="detected" onClick={onClick} data-slug="deviceSecurity.deviceCard.editDevice">
        <FontAwesomeIcon icon={iconOS[device.operatingSystem!]} />
        <div className="deviceName" data-slug="deviceSecurity.deviceCard.editDevice">
          {i18n.getLanguage() === 'es' ? (
            <>
              {' '}
              <span style={{ textTransform: 'capitalize' }}>{i18n.t('deviceSecurity.deviceCard.device')}</span>
              {' '}
              {i18n.t(`deviceSecurity.deviceCard.${device.operatingSystem}`)}
            </>
          )
            : (
              <>
                {i18n.t(`deviceSecurity.deviceCard.${device.operatingSystem}`)}
                {' '}
                {i18n.t('deviceSecurity.deviceCard.device')}
              </>
            )}
        </div>
        <DeviceSecurityStatus status="detected" data-slug="deviceSecurity.deviceCard.editDevice" />
        <button
          data-slug="deviceSecurity.deviceCard.editDevice"
          type="button"
          style={{
            background: 'none',
            border: 'none',
            color: 'white',
            padding: '5px',
            fontSize: '17px',
            fontWeight: 300,
            marginLeft: 'auto',
          }}
        >
          {i18n.t('deviceSecurity.deviceCard.completeSetup')}
          <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: '11px', fontSize: '20px' }} />
        </button>
      </DeviceCardWrapper>
    );
  }
  return (
    <DeviceCardWrapper status={getDeviceSurveyStatus(device)} onClick={onClick} data-slug="deviceSecurity.deviceCard.editDevice">
      <FontAwesomeIcon icon={iconOS[device.operatingSystem!]} />
      <div className="deviceName" data-slug="deviceSecurity.deviceCard.editDevice">
        {i18n.getLanguage() === 'es' ? (
          <>
            {' '}
            <span style={{ textTransform: 'capitalize' }}>{i18n.t('deviceSecurity.deviceCard.device')}</span>
            {' '}
            {i18n.t(`deviceSecurity.deviceCard.${device.operatingSystem}`)}
          </>
        )
          : (
            <>
              {i18n.t(`deviceSecurity.deviceCard.${device.operatingSystem}`)}
              {' '}
              {i18n.t('deviceSecurity.deviceCard.device')}
            </>
          )}
      </div>
      <DeviceSecurityStatus status={getDeviceSurveyStatus(device)} data-slug="deviceSecurity.deviceCard.editDevice" />
      {
        getDeviceSurveyStatus(device) === 'secured' && (
          <button
            data-slug="deviceSecurity.deviceCard.editDevice"
            type="button"
            style={{
              background: 'none',
              border: 'none',
              color: '#2B2B2B',
              padding: '5px',
              fontSize: '17px',
              fontWeight: 600,
              marginLeft: 'auto',
            }}
          >
            <FontAwesomeIcon icon={faPen} style={{ marginRight: '11px' }} />
            {i18n.t('deviceSecurity.deviceCard.editDevice')}
          </button>
        )
      }
      {
        getDeviceSurveyStatus(device) === 'unsecured' && (
          <button
            data-slug="deviceSecurity.deviceCard.fixUnsecured"
            type="button"
            style={{
              background: 'none',
              border: 'none',
              color: 'white',
              padding: '5px',
              fontSize: '17px',
              fontWeight: 300,
              marginLeft: 'auto',
            }}
          >
            {i18n.t('deviceSecurity.deviceCard.fixUnsecured')}
            <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: '11px', fontSize: '20px' }} />
          </button>
        )
      }
      {
        getDeviceSurveyStatus(device) === 'notCompleted' && (
          <button
            data-slug="deviceSecurity.deviceCard.completeSetup"
            type="button"
            style={{
              background: 'none',
              border: 'none',
              color: 'white',
              padding: '5px',
              fontSize: '17px',
              fontWeight: 300,
              marginLeft: 'auto',
            }}
          >
            {i18n.t('deviceSecurity.deviceCard.completeSetup')}
            <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: '11px', fontSize: '20px' }} />
          </button>
        )
      }
    </DeviceCardWrapper>
  );
};

export default DeviceCard;

const DeviceCardWrapper = styled.div`
width: 100%;
max-width: 900px;
align-items: center;
display: flex;
padding: 17px 32px;
border-radius: 34px;
box-shadow: rgba(0,0,0,0.06) 0 2px 33px 3px;
margin-bottom: 26px;
font-size: 21px;
line-height: 28px;
font-weight: 800;
color: ${(props:any) => (props.status === 'secured' ? '#3580F9' : 'white')};
background-color: ${(props:any) => (props.status === 'unsecured' ? '#FF7373' : props.status === 'notCompleted' ? '#FF9A3A' : props.status === 'detected' ? '#3580F9' : 'white')};
 & > svg {
   margin-right: 24px;
 }
.deviceName {
  width: 100%;
  max-width: 200px;
}
@media screen and (max-width: 500px) {
  padding 10px 10px;
  & > svg {
    margin-right: 14px;
  }
}
`;
