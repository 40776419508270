import React from 'react';
import styled from '@emotion/styled';
import ModalImage from 'react-modal-image';
import { faQuestionCircle, faVideo, faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { i18n } from '../../i18n';
import { questionMedia } from '../helper';

const CheckMeasurement:React.FC<{
  activeIssue: string, answers:any, close: () => void
}> = ({ activeIssue, answers, close }) => (
  <Wrapper>
    <FontAwesomeIcon
      icon={faTimesCircle}
      style={{
        color: 'black', fontSize: '26px', position: 'absolute', right: '20px', top: '13px',
      }}
      onClick={close}
    />
    <h1 style={{ fontSize: '26px', display: 'flex' }}>
      <FontAwesomeIcon icon={faQuestionCircle} style={{ marginRight: '24px' }} />
      {activeIssue !== 'autoUpdateBrowser'
        && !(answers.browser === 'IE' && answers.operatingSystem === 'window')
        && !(answers.browser === 'safari' && answers.operatingSystem === 'macOS')
        && (<div>{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.checkTitle`)}</div>)}
      {activeIssue !== 'autoUpdateBrowser' && activeIssue === 'autoUpdateOS'
        && answers.browser === 'IE' && answers.operatingSystem === 'window'
        && (<div>{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.windowsIE.checkTitle`)}</div>)}
      {activeIssue !== 'autoUpdateBrowser' && activeIssue === 'autoUpdateOS'
        && answers.browser === 'safari' && answers.operatingSystem === 'macOS'
        && (<div>{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.macOSSafari.checkTitle`)}</div>)}
      {activeIssue === 'autoUpdateBrowser' && (<div>{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.checkTitle`)}</div>)}
    </h1>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div>
        {Array.isArray(i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.check`))
          && i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.check`)
          && !(answers.browser === 'IE' && answers.operatingSystem === 'window')
          && !(answers.browser === 'safari' && answers.operatingSystem === 'macOS')
          && activeIssue !== 'autoUpdateBrowser' && (
            <>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.check`)[0]}</p>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.check`)[1]}</p>
            </>
        )}
        {Array.isArray(i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.windowsIE.check`))
          && i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.windowsIE.check`)
          && activeIssue === 'autoUpdateOS'
          && answers.browser === 'IE' && answers.operatingSystem === 'window'
           && (
           <>
             <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.windowsIE.check`)[0]}</p>
             <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.windowsIE.check`)[1]}</p>
           </>
           )}
        {Array.isArray(i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.macOSSafari.check`))
          && i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.macOSSafari.check`)
          && activeIssue === 'autoUpdateOS'
          && answers.browser === 'safari' && answers.operatingSystem === 'macOS'
           && (
           <>
             <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.macOSSafari.check`)[0]}</p>
             <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.macOSSafari.check`)[1]}</p>
           </>
           )}
        {Array.isArray(i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.check`))
          && i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.check`)
          && activeIssue === 'autoUpdateBrowser' && (
            <>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.check`)[0]}</p>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.check`)[1]}</p>
            </>
        )}
        {(activeIssue === 'autoUpdateBrowser' && questionMedia[activeIssue][answers.operatingSystem][answers.browser].tutorial) && (
          <button
            data-slug="deviceSecurity.deviceQuestion.viewTutorialPDF"
            className="outline"
            type="button"
            onClick={() => window.open(questionMedia[activeIssue][answers.operatingSystem][answers.browser].tutorial[i18n.lang], '_blank')}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ marginRight: '19.5px', fontSize: '24px', verticalAlign: 'bottom' }}
            />
            {i18n.t('deviceSecurity.deviceQuestion.viewTutorialPDF')}
          </button>
        )}
        {(activeIssue !== 'autoUpdateBrowser' && questionMedia[activeIssue][answers.operatingSystem].tutorial)  && (
          <button
            data-slug="deviceSecurity.deviceQuestion.viewTutorialPDF"
            className="outline"
            type="button"
            onClick={() => window.open(questionMedia[activeIssue][answers.operatingSystem].tutorial[i18n.lang], '_blank')}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ marginRight: '19.5px', fontSize: '24px', verticalAlign: 'bottom' }}
            />
            {i18n.t('deviceSecurity.deviceQuestion.viewTutorialPDF')}
          </button>
        )}
      </div>
      {activeIssue === 'autoUpdateBrowser' && questionMedia[activeIssue]?.[answers.operatingSystem]?.[answers.browser]?.gif?.[i18n.lang] && (
        <div className="imageContent">
          <ModalImage
            small={questionMedia[activeIssue][answers.operatingSystem][answers.browser].gif[i18n.lang]}
            large={questionMedia[activeIssue][answers.operatingSystem][answers.browser].gif[i18n.lang]}
            alt="gif"
            style={{
              width: '100%',
              borderRadius: '3px',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
            }}
          />
        </div>
      )}
      {activeIssue === 'autoUpdateBrowser' && questionMedia[activeIssue]?.[answers.operatingSystem]?.[answers.browser]?.video?.[i18n.lang] && (
        <div className="imageContent">
          <video
            controls
            autoPlay
            src={questionMedia[activeIssue][answers.operatingSystem][answers.browser].video[i18n.lang]}
            style={{
              width: '100%',
              height: '208px',
              borderRadius: '3px',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
            }}
          />
        </div>
      )}
      {activeIssue !== 'autoUpdateBrowser' && questionMedia[activeIssue][answers.operatingSystem]?.gif?.[i18n.lang]
        && (
        <div className="imageContent">
          <ModalImage
            small={questionMedia[activeIssue][answers.operatingSystem].gif[i18n.lang]}
            large={questionMedia[activeIssue][answers.operatingSystem].gif[i18n.lang]}
            alt="gif"
            style={{
              width: '100%',
              borderRadius: '3px',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
            }}
          />
        </div>
      )}
      {activeIssue !== 'autoUpdateBrowser' && questionMedia[activeIssue][answers.operatingSystem]?.video?.[i18n.lang]
        && (
        <div className="imageContent">
          <video
            controls
            autoPlay
            src={questionMedia[activeIssue][answers.operatingSystem].video[i18n.lang]}
            style={{
              width: '100%',
              height: '208px',
              borderRadius: '3px',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
            }}
          />
        </div>
      )}

    </div>
  </Wrapper>
);


export default CheckMeasurement;

const Wrapper = styled.div`
width: 100%;
max-width: 870px;
border-radius: 8px;
background-color: white;
padding: 28px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

& p {
  font-weight: 400;
}

& p.intro {
  width: 100%; 
  max-width: 812px;
  font-size: 19px;
  color: rgb(111, 111, 111);
  letter-spacing: 0.3px;
}

& p.content {
  font-size: 18px;
  color: rgb(83, 83, 83);
  max-width: 370px;
  width:100%;
  margin-top: 16px;
  margin-bottom: 0;
}

& h3 {
  font-size:18px;
  font-weight: 900;
  color: rgb(65, 65, 65);
  margin: 35px 0 10px;
}

& button.outline {
  border: 1px solid rgb(53, 128, 249);
  border-radius: 4.55px;
  padding: 10px 0;
  color: rgb(53, 128, 249);
  font-size: 19.5px;
  font-weight: 400;
  background-color: #fff;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  max-width: 249px;
}

& button.back {
  border: none;
  padding: 10px 0;
  color: rgb(75, 75, 75);
  font-size: 21px;
  font-weight: 500;
  background-color: #fff;
  text-align: center;
  width: 100%;
  max-width: 114px;
}

& button.confirm {
  border: 1px solid rgb(53, 128, 249);
  border-radius: 4.8px;
  padding: 10px 0;
  color: rgb(255, 255, 255);
  font-size: 19.5px;
  font-weight: 500;
  background-color: rgb(53, 128, 249);
  text-align: center;
  width: 100%;
  max-width: 189px;
  margin-right: 40px;
}
& button.confirm:disabled {
  opacity: 0.4;
}

.imageContent {
  background-color: rgb(216, 216, 216);
  border-radius: 5.2px;
  width: 100%;
  height: 208px;
  max-width: 366.6px;
  text-align: center;
  position: relative;
}

`;
