import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Spinner from './components/Spinner';
import { i18n } from './i18n';
import { authUser } from './redux/actions/auth.actions';

const Authentication:React.FC<{children:any}> = ({ children }) => {
  const auth = useSelector((redux:any) => redux.auth);
  const user = useSelector((redux:any) => redux.user);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [location]);

  useEffect(() => {
    dispatch(authUser(true));
  }, []);

  useEffect(() => {
    setLoading(false);
    if (((!auth || !auth.currentUser) && !loading)) {
      nav(`/${i18n.getLanguage()}`);
    }
  }, [auth]);

  if (auth && !loading) {
    return children;
  }
  return (
    <div style={{
      position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
    }}
    >
      {' '}
      <Spinner loading={loading} />
    </div>

  );
};

export default Authentication;
