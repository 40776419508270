import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';

import Progress from './Progress';
import QuestionWrapper from './QuestionWrapper';
import Questions from './Questions';
import QuestionResult from './QuestionResult';
import QuizResults from './QuizResults';
import '../App.css';

import { UserContext } from '../../user';

const Box = styled.div`
width: 80vw;
max-width: 1743px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background: white;
border-radius: 15px;
padding: 30px 48px;
max-height: 100%;
overflow-y: auto;
@media all and (-ms-high-contrast:none)
{
& {
  max-height: none;
}
}
@media screen and (max-height: 1070px) {
  transform: translate(-50%,-50%) scale(0.8);
  max-height: none;
}

@media screen and (max-height: 700px) {
  transform: translate(-50%,-50%) scale(0.6);
  width: 100vw;
  padding: 20px;
}
${(props) => (props.submiting ? '& > * { filter: blur(30px)}' : '')}
`;

function App() {
  const [stats, setStats] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const QuestionContect = Questions[currentQuestion];
  const [submiting, setSubmiting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [finish, setFinish] = useState(false);
  const user = React.useContext(UserContext);

  const saveResults = (started, completed) => {
    const data = {
      userId: user.userId,
      clientId: user.clientId,
      trainingId: 'phishing',
      result: stats,
    };

    if (started) {
      data.status = 'started';
    }

    if (completed) {
      data.status = 'completed';
    }

    axios.post('/training', data)
      .catch((error) => {
        console.error('Failed to save: ', error);
        return false;
      });
  };

  if (progress == 0) {
    saveResults(true, false);
  }

  const switchQuestion = () => {
    if (currentQuestion < Questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSubmiting(false);
    } else {
      setSubmiting(false);
    }
    saveResults(false, false);
  };

  const finishQuiz = () => {
    setSubmiting(false);
    setFinish(true);
    saveResults(false, true);
  };

  let found; let total; let
    type;
  useEffect(() => {
    if (submiting) {
      if (Questions && Questions.length) setProgress((currentQuestion + 1) / (Questions.length));
    }
  }, [submiting]);


  if (submiting) {
    found = Object.keys(stats[currentQuestion].found).length;
    total = stats[currentQuestion].total;
    type = stats[currentQuestion].type;
  }

  return (
    <div className="tutorialWrapper">
      <Box>
        {finish && <QuizResults stats={stats} />}
        {!finish && (
          <>
            <Progress question={currentQuestion + 1} progress={progress} />
            <QuestionWrapper
              stats={stats}
              setStats={setStats}
              setCurrentQuestion={setCurrentQuestion}
              currentQuestion={currentQuestion}
              setSubmiting={setSubmiting}
              submiting={submiting}
              total={total}
              switchQuestion={switchQuestion}
              finishQuiz={finishQuiz}
            >
              <QuestionContect
                stats={stats}
                setStats={setStats}
                currentQuestion={currentQuestion}
                submiting={submiting}
              />
            </QuestionWrapper>
          </>
        )}
      </Box>
    </div>
  );
}

export default App;
