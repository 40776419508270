import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { useTranslation } from '../../i18n';

const CorrectWrapper = styled.div`
display: inline-block;
color: #1DD22D;
padding: 0 20px;
vertical-align: middle;
font-size: 20px;
font-weight: 600;
`;

const Correct = () => {
  const i18n = useTranslation();
  return (
    <CorrectWrapper>
      <FontAwesomeIcon icon={faCheckCircle} />
&nbsp;&nbsp;
      {i18n.t('quiz.correct')}
    </CorrectWrapper>
  );
};
export default Correct;
