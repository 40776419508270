import ReactGA from 'react-ga';
import axios from 'axios';
import { firestore } from '../../firebase';
import { GET_USER, UPDATE_USER } from './types';
import { i18n } from '../../i18n';
import { ErrorKey } from '../../track';
import { getDevices } from './devices.actions';

export const getUser = (uid:string) => async (dispatch: Function) => {
  const user = await firestore.collection('users').doc(uid).get()
    .then((doc:any) => doc.data());
  if (user && user.language) localStorage.setItem('language', user.language);
  if (user && !user.language) localStorage.setItem('language', 'en');
  if (!user) {
    axios.get('/employee/createUser')
      .then(async (res) => {
        dispatch({
          type: GET_USER,
          payload: { uid, ...res.data },
        });
        await getCompanyDetails(dispatch, { uid, ...res.data });
        dispatch(getDevices());
        userAnalytics(uid, res.data);
      });
  } else {
    dispatch({
      type: GET_USER,
      payload: { uid, ...user },
    });
    await getCompanyDetails(dispatch, { uid, ...user });
    dispatch(getDevices());
    userAnalytics(uid, user);
  }
};

const userAnalytics = (uid:string, user: any) => {
  if (!user) return;
  ReactGA.set({ userId: uid });
  // dimension1 == clientId
  ReactGA.set({ dimension1: `${user.employeeAt}` });
  // dimension2 == Licensee - updated by getCompanyDetails
  // dimension3 == emailDomain
  if (user.email) {
    ReactGA.set({ dimension3: `${user.email.split('@').pop()}` });
  }
  // dimension4 == PartnerAccount - updated by getCompanyDetails
  // dimension5 == role
  if (user.role) {
    ReactGA.set({ dimension5: `${user.role}` });
  }
  // dimension7 == Language
  if (user.language) {
    ReactGA.set({ dimension7: `${user.language}` });
  }
};

type UserData = {
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
}

const validateForm = (payload: UserData, user: any): boolean => {
  if (!user || !user.uid) {
    ErrorKey('errors.thereWasAnError', 'validateForm');
    return false;
  }
  const { firstName, lastName, phoneNumber } = payload;
  const invalid = [];
  if (!firstName && !user.firstName) invalid.push(i18n.t('profile.name').toLowerCase());
  if (!lastName && !user.lastName) invalid.push(i18n.t('profile.lastName').toLowerCase());
  if (phoneNumber && phoneNumber[0] !== '+') invalid.push(`${i18n.t('profile.phone').toLowerCase()} (${i18n.t('profile.phoneEx')})`);
  if (!firstName && !user.firstName) invalid.push('Name'.toLowerCase());
  if (!lastName && !user.lastName) invalid.push('Last name'.toLowerCase());
  if (phoneNumber && phoneNumber[0] !== '+') invalid.push(`${'Phone number'.toLowerCase()} (${'e.g. +4465412343'})`);
  if (invalid.length > 0) {
    const msg = invalid.map((item, index) => {
      if (index === 0) {
        return item;
      } if (index !== 0 && index < invalid.length - 1) {
        return `, ${item}`;
      }
      return `, ${item}`; // Change for translation from: return ` and ${item}`;
    });
    ErrorKey('errors.thereWasAnErrorWith', 'validateForm', { errormessage: msg.join('') });
    return false;
  }
  return true;
};

export const updateUser = (payload: UserData, user: any) => async (dispatch: any) => {
  const { firstName, lastName } = user;
  if (validateForm(payload, user)) {
    const success = await axios.patch(`/users/${user.uid}`, { firstName, lastName, ...payload })
      .then(() => {
        dispatch({
          type: UPDATE_USER,
          payload: { ...user, ...payload },
        });
        return true;
      })
      .catch(() => {
        ErrorKey('errors.thereWasAnError', 'updateUser');
        return false;
      });
    return success;
  }
  return false;
};

export const update2fa = ({
  requires2fa,
  verified2fa,
}:{requires2fa: boolean, verified2fa: boolean}) => (dispatch: Function, getState: Function) => {
  const { user } = getState();
  const newUser = { ...user, requires2fa, verified2fa };
  dispatch({
    type: UPDATE_USER,
    payload: newUser,
  });
};

const storeClientAccounts = (accounts: any, uuid:string) => {
  localStorage.setItem(`${uuid}-clientAccountsArray`, JSON.stringify(accounts));
};

export const getClientAccountsLocalStored = (uuid:string) => localStorage.getItem(`${uuid}-clientAccountsArray`);

export const storeActiveClientAccount = (clientId:string, uuid:string) => {
  localStorage.setItem(`${uuid}-activeClientAccount`, clientId);
};

export const getActiveClientAccount = (uuid:string) => localStorage.getItem(`${uuid}-activeClientAccount`);

const getAccountById = (accounts:any,
  activeClientId:string) => accounts.find((item:any) => item.clientId === activeClientId);

const getCompanyDetails = (dispatch:any, userObject:any) => axios.get('/company')
  .then((res) => {
    if (!res || !res.data || res.data.length === 0) return;
    const clientz = res.data;
    const { uid } = userObject;
    storeClientAccounts(clientz, uid);
    const activeClientId = getActiveClientAccount(uid) || clientz[0].clientId;
    storeActiveClientAccount(activeClientId, uid);
    const activeAccount = getAccountById(clientz, activeClientId);
    dispatch({ type: UPDATE_USER, payload: { ...userObject, ...activeAccount } });
    // dimension2 == Licensee
    if (res.data.licensee) {
      ReactGA.set({ dimension2: `${res.data.licensee}` });
    }
    // dimension4 == PartnerAccount
    if (res.data.partner) {
      ReactGA.set({ dimension4: `${res.data.partner}` });
    }
  });


export default 1;
