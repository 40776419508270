import {
  GET_DEVICES, UPDATE_DEVICES,
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case GET_DEVICES:
      return action.payload || [];
    case UPDATE_DEVICES:
      return action.payload || [];
    default:
      return state;
  }
};
