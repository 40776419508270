import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faCheck, faMinus, faThunderstorm, faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { i18n } from '../../i18n';

const DeviceSecurityStatus:React.FC<{status: 'secured' | 'unsecured' | 'noDevice' | 'noSurveySent'| 'notCompleted'| 'detected'}> = ({ status }) => {
  if (status === 'secured') {
    return (
      <div style={{
        color: 'white',
        backgroundColor: '#8ABF52',
        borderRadius: 16,
        padding: '2px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 500,
        width: '100%',
        maxWidth: '290px',
      }}
      >
        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px', marginLeft: 0 }} />
        {i18n.t('deviceSecurity.deviceCard.secured')}
      </div>
    );
  }
  if (status === 'unsecured') {
    return (
      <div style={{
        color: '#FF5757',
        backgroundColor: 'white',
        borderRadius: 16,
        padding: '2px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 500,
        width: '100%',
        maxWidth: '268px',
      }}
      >
        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px', marginLeft: 0 }} />
        {i18n.t('deviceSecurity.deviceCard.unsecured')}
      </div>
    );
  }
  if (status === 'detected') {
    return (
      <div style={{
        color: '#3580F9',
        backgroundColor: 'white',
        borderRadius: 16,
        padding: '2px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 500,
        width: '100%',
        maxWidth: '268px',
      }}
      >
        <FontAwesomeIcon icon={faBolt} style={{ marginRight: '10px', marginLeft: 0 }} />
        {i18n.t('deviceSecurity.deviceCard.autoDetected')}
      </div>
    );
  }
  if (status === 'notCompleted') {
    return (
      <div style={{
        color: '#FF9A3A',
        backgroundColor: 'white',
        borderRadius: 16,
        padding: '2px',
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: 500,
        width: '100%',
        maxWidth: '268px',
      }}
      >
        <FontAwesomeIcon icon={faMinus} style={{ marginRight: '10px', marginLeft: 0 }} />
        {i18n.t('deviceSecurity.deviceCard.notCompleted')}
      </div>
    );
  }
  if (status === 'noDevice') {
    return (
      <div style={{
        color: '#C9C9C9',
        padding: '2px',
        fontSize: '18px',
        fontWeight: 500,
      }}
      >
        {i18n.t('deviceSecurity.deviceCard.noDeviceReported')}
      </div>
    );
  }
  if (status === 'noSurveySent') {
    return (
      <div style={{
        color: '#C9C9C9',
        padding: '2px',
        fontSize: '18px',
        fontWeight: 500,
      }}
      >
        {i18n.t('deviceSecurity.deviceCard.noSurveySent')}
      </div>
    );
  }
  return null;
};

export default DeviceSecurityStatus;
