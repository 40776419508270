import React, { useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { i18n } from '../../i18n';

const RequestPermissionForCompanyDevice:React.FC<{accept: ()=> void, cancel: ()=> void}> = ({ accept, cancel }) => {
  const [hasPersmission, setHasPersmission] = useState(false);
  return (
    <RequestWrapper>
      <FontAwesomeIcon
        icon={faTimesCircle}
        style={{
          color: 'black', fontSize: '26px', position: 'absolute', right: '20px', top: '13px', cursor: 'pointer',
        }}
        onClick={cancel}
      />
      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#FF9F00', fontSize: '33px' }} />
      <p>
        {i18n.t('deviceSecurity.deviceQuestion.requestPermission.title')}
      </p>
      <label htmlFor="hasPermission">
        <input id="hasPermission" type="checkbox" value="permission" checked={hasPersmission} onClick={() => setHasPersmission(!hasPersmission)} />
        {i18n.t('deviceSecurity.deviceQuestion.requestPermission.label')}
      </label>
      <br />
      <button type="button" disabled={!hasPersmission} onClick={accept}>
        {i18n.t('deviceSecurity.deviceQuestion.requestPermission.continue')}
        <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: '13px' }} />
      </button>
    </RequestWrapper>
  );
};

export default RequestPermissionForCompanyDevice;

const RequestWrapper = styled.div`
width: 100%;
max-width: 560px;
padding: 30px;
text-align:center;
position:absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: white;
border-radius: 5px;

input {
  transform: scale(1.2);
  margin-right: 17px;
}

p {
  color: #535353;
  font-weight: 900;
  font-size: 22px;
  margin-top: 23px;
  margin-bottom: 22px;

}
label {
  background-color: #F5F5F5;
  border-radius: 5px;
  padding: 13px 24px;
  text-align: center;
  color: black;
  margin-bottom: 32px;
  font-size:16px;
  display:inline-block;
}
button {
background-color: #3580F9;
color: white;
border-radius: 2px;
padding: 9px 20px;
text-align: center;
font-size: 16px;
border:none;
}
button:disabled {
  opacity: 0.4;
}
`;
