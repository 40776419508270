import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { faCheck, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { authUser, sendSignInEmailLink } from '../redux/actions/auth.actions';
import firebase, { auth } from '../firebase';
import { i18n } from '../i18n';
import { ErrorKey } from '../track';
import manIcon from '../img/focused_working.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const licensee = useSelector((redux) => redux.licensee);
  const dispatch = useDispatch();
  const authObject = useSelector((redux) => redux.auth);
  const history = useNavigate();

  const handleLogin = (email) => {
    if (sent) return;
    axios.get(`/employee/${email}`)
      .then((res) => {
        dispatch(sendSignInEmailLink(email));
        setSent(true);
      })
      .catch((err) => {
        ErrorKey('error.emailNotFound', 'emailLink');
      });
  };

  useEffect(() => {
    if (authObject === null) {
      dispatch(authUser());
    }
    if (authObject && authObject.currentUser) {
      history(`/${i18n.getLanguage()}/device-security`);
    }
  }, [authObject]);

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      firebase.auth().languageCode = 'en';
      if (i18n.getLanguage()) firebase.auth().languageCode = i18n.getLanguage();
      firebase.auth().signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          history(`/${i18n.getLanguage()}/device-security`);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);
  return (
    <>
      <SignInWrapper>
        <EmailInPutWrapper>
          <h3>Cyber Guardian</h3>
          <h1>
            {i18n.t('signIn.title')}
          </h1>
          <p>
            {i18n.t('signIn.suibTitle')}
          </p>
          <label htmlFor="email">
            {i18n.t('signIn.email')}
            {' '}
            <br />
            <label htmlFor="email" style={{ textAlign: 'left', fontWeight: 700 }}>
              <input
                name="email"
                id="email"
                type="text"
                className="form-control"
                placeholder={i18n.t('signIn.exEmail')}
                value={email}
                onChange={(e) => setEmail(e.target.value.replace(/ /g, '').toLowerCase())}
                style={{ width: '100%', textAlign: 'left', fontSize: '18px' }}
              />
            </label>

            <button
              type="button"
              id="next"
              className={`btn btn-default${sent ? ' sent' : ''}`}
              onClick={() => handleLogin(email?.toLowerCase())}
              disabled={!email}
            >
              {sent && (
                <>
                  <FontAwesomeIcon icon={faCheck} style={{ marginRight: '20px' }} />
                  {i18n.t('signIn.magicLinkSent')}
                </>
              )}
              {!sent && i18n.t('signIn.sendEmailLink')}
            </button>
            {sent && (
              <div style={
              {
                padding: '19px 24px',
                backgroundColor: '#F6F6F6',
                marginTop: '27px',
                display: 'flex',
                flexWrap: 'nowrap',
              }
              }
              >
                <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#3580F9', fontSize: '25px', marginRight: '25px' }} />
                <h4>
                  {i18n.t('signIn.pleaseCheckEmail')}
                  <p>
                    {i18n.t('signIn.pleaseCheckEmailParagraph')}
                  </p>
                </h4>
              </div>
            )}
          </label>
          <div style={{ margin: '60px 0' }}>
            <a
              target="_blank"
              rel="noreferrer"
              style={{
                color: '#363636',
                textAlign: 'left',
                marginRight: '30px',
                marginBottom: '10px',
                textDecoration: 'none',
                display: 'inline-block',
                whiteSpace: 'nowrap',
              }}
              href={licensee.termsOfUse}
            >
              {i18n.t('termsOfUse')}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              style={{
                color: '#363636', textAlign: 'left', textDecoration: 'none', whiteSpace: 'nowrap', display: 'inline-block',
              }}
              href={licensee.privacyPolicy}
            >
              {i18n.t('privacyPolicy')}

            </a>
          </div>
        </EmailInPutWrapper>
        <ManOnDeskWrapper><img src={manIcon} alt="focused working" /></ManOnDeskWrapper>
      </SignInWrapper>
    </>
  );
};

export default Login;

const EmailInPutWrapper = styled.div`
width: 60%;
background-color: white;
padding: 15% 8%;
@media screen and (max-width: 799px) {
  width: 100%;
  padding:  10% 5%;
}

h4 {
  color: #3580F9;
  font-size: 22px;
  font-weight: 900;
  max-width: 674px;
  margin:0;
  display:inline-block;
  vertical-align:top;

  p {
    color: #646464;
    font-weight: 400;
    font-size: 20px;
  }
}

h3 {
  font-family: Nexa-bold;
  color: #3580F9;
  font-size:42px;
  margin:0;
  max-width: 674px;
}

h1 {
  font-size: 60px;
  font-weight: 800;
  color: #535353;
  margin-bottom: 25px;
  max-width: 674px;
}

p {
  font-weight: 400;
  font-size: 20px;
  color: #535353;
  max-width: 674px;
}
label {
  color: #7D7D7D;
  font-size: 20px;
  font-weight: 600;
  margin-top: 25px;
  max-width: 674px;
}

button.sent {
  background-color:#1EB72F;
  color: white;
}
`;

const ManOnDeskWrapper = styled.div`
width: 40%;
position:relative;

& img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 799px) {
display:none;
}
`;

const SignInWrapper = styled.div`
 {
  font-family: lato;
  width: 100vw;
  height: 100vh;
  display: flex;

  button {
    border-radius: 5px;
    font-size: 20px;
    margin-top: 29px;
    font-weight: 600;
    letter-spacing: .8px;
    padding: 15px;
    background-color: #3580F9;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:disabled {
      opacity: 0.4;
    }
  }
  & input {
    margin-top: 14px;
    margin-right: 40px;
    max-width: 440px;
    padding: .75rem .875rem;
    height: 55px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    box-sizing: border-box;
    display: block;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  }
}
`;
