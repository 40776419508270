import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGrimace, faSadCry, faSmile,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from '../../i18n';

const ResultWrapper = styled.div`
filter: blur(0px);
position: absolute;
width: 100%;
height: 100%;
text-align: center;
top: 0;
left: 0;

@media all and (-ms-high-contrast:none)
{
& {
  background-color: white;
}
}
`;

const Content = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform:translate(-50%,-50%);
color: ${(props) => props.color};
`;

const Button = styled.button`
width: 100%;
max-width: 303px;
height: 68px;
border-radius: 4px;
background-color: #bc1224;
color: white;
font-size: 30px;
font-weight: 700;
border:none;
cursor: pointer;
`;

const QuestionResult = (props) => {
  const {
    found, total, type, switchQuestion, progress, finishQuiz,
  } = props;
  const i18n = useTranslation();
  let icon = faSadCry;
  let title = i18n.t('quiz.failedQuestion');
  let subTitle = type === 'email' ? i18n.t('quiz.lookCarefully') : i18n.t('quiz.considerCarefully');
  let color = '#E36666';
  if (found === total) {
    icon = faSmile;
    title = type === 'email' ? i18n.t('quiz.foundAll') : i18n.t('quiz.selectedAllCorrect');
    if (total === 1 && type !== 'email') {
      title = i18n.t('quiz.correctSelected');
    }
    subTitle = i18n.t('quiz.niceJob');
    color = '#4AB52C';
  } else if (found > 0 && found < total) {
    icon = faGrimace;
    title = i18n.t('quiz.missedX', { number: (total - found) });
    subTitle = type === 'email' ? i18n.t('quiz.lookCarefully') : i18n.t('quiz.considerCarefully');
    color = '#F7B500';
  }
  return (
    <ResultWrapper>
      <Content color={color}>
        <FontAwesomeIcon icon={icon} style={{ fontSize: '98px' }} />
        <div style={{ margin: '37px 0 14px 0', fontSize: '60px', fontWeight: 900 }}>{title}</div>
        <div style={{ fontSize: '30px', marginBottom: '44px', color: '#444444' }}>{subTitle}</div>
        { progress < 1 && (
        <Button onClick={switchQuestion}>
          {i18n.t('quiz.nextQuestion')}
          {' '}
          &nbsp;
          <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '26px' }} />
        </Button>
        )}
        { progress === 1 && (
        <Button onClick={finishQuiz}>
          {i18n.t('finish')}
          {' '}
          &nbsp;
          <FontAwesomeIcon icon={faCheck} style={{ fontSize: '26px' }} />
        </Button>
        )}
      </Content>
    </ResultWrapper>
  );
};

export default QuestionResult;
