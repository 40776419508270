import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { faArrowCircleLeft, faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate, useParams } from 'react-router';
import { signOut } from '../redux/actions/auth.actions';
import { i18n } from '../i18n';
import { addDevice } from '../redux/actions/devices.actions';
import { getActiveClientAccount, getClientAccountsLocalStored, storeActiveClientAccount } from '../redux/actions/user.actions';


const Logo = styled.h2`
font-family: Nexa-Bold;
font-size: 60px !important;
letter-spacing: 3px;
display: block;
color:#3580F9;
margin-left: auto;
margin-right: auto;
margin-top: 10px;
width: 150px;

@media screen and (max-width: 1000px) {
  display: none;
}
`;

const SideBar:React.FC<{mode?:string, section?:number, answers?:any}> = ({ mode, section, answers }) => {
  const dispatch = useDispatch();
  const licensee = useSelector((redux:any) => redux.licensee);
  const user = useSelector((redux:any) => redux.user);
  const history = useNavigate();
  const { id } = useParams();

  const [clientAccounts, setClientAccounts] = useState<any[]>([]);
  const [activeClientId, setActiveClientId] = useState<string>();
  useEffect(() => {
    if (user?.uid) {
      const { uid } = user;
      if (!getClientAccountsLocalStored(uid)) return;
      setClientAccounts(JSON.parse(getClientAccountsLocalStored(uid) || ''));
      setActiveClientId(getActiveClientAccount(uid) || '');
    }
  }, [user]);
  const returnToDevices = () => {
    if (section && section > 0 && answers) {
      if (!id || id === 'detected') {
        dispatch(addDevice(answers));
      }
    }
    history(`/${i18n.getLanguage()}/device-security`);
  };

  if (mode === 'device') {
    return (
      <SideBarWrapper id="sidebar">
        <h2
          style={{
            cursor: 'pointer', fontSize: '23px', fontWeight: 500, color: 'rgb(57, 57, 57)',
          }}
          onClick={returnToDevices}
        >
          <FontAwesomeIcon icon={faArrowCircleLeft} style={{ color: 'rgb(61, 61, 61)', marginRight: '16px', fontSize: '24px' }} />
          {i18n.t('deviceSecurity.sideBar.returnToDevices')}
        </h2>
        <h6 style={{
          fontSize: '17px', fontWeight: 400, color: 'rgb(79, 79, 79)', margin: '41px 0 0 0',
        }}
        >
          {i18n.t('deviceSecurity.sideBar.progress')}
        </h6>
        <div className="sidemenu">
          <div className={`${!section ? 'current' : 'completed'}`}>
            <h3>
              <FontAwesomeIcon className="shown" icon={faCheckCircle} />
              <FontAwesomeIcon className="notShown" icon={faCircle} />
              {i18n.t('deviceSecurity.sideBar.deviceDetails')}
            </h3>
          </div>
          <div className={`${section === 1 ? 'current' : section === 0 ? 'next' : 'completed'}`}>
            <h3>
              <FontAwesomeIcon className="shown" icon={faCheckCircle} />
              <FontAwesomeIcon className="notShown" icon={faCircle} />
              {i18n.t('deviceSecurity.sideBar.securityQuestions')}
            </h3>
          </div>
          <div className={`${(section && section >= 2) ? 'current' : 'next'}`}>
            <h3>
              <FontAwesomeIcon className="shown" icon={faCheckCircle} />
              <FontAwesomeIcon className="notShown" icon={faCircle} />
              {i18n.t('deviceSecurity.sideBar.summary')}
            </h3>
          </div>
          <Logo><img src="https://storage.googleapis.com/cyberguardian-assets/Training/Phishing%20Training/Assets/Images/Logo-training.svg" width="270" /></Logo>
        </div>

      </SideBarWrapper>
    );
  }

  return (
    <SideBarWrapper id="sidebar">
      <Logo><img src="https://storage.googleapis.com/bewica-assets/Logo-training.svg" width="270" /></Logo>
      {clientAccounts && clientAccounts.length > 1 && (
      <select
        style={{
          backgroundColor: '#BC1224',
          color: 'white',
          border: '1px solid rgba(255,255,255,0.34)',
          borderRadius: '3px',
          fontSize: '14px',
          padding: '7px 13px',
          marginTop: '-30px',
          marginBottom: '45px',
          width: '218px',
        }}
        onChange={(e) => { storeActiveClientAccount(e.target.value, user.uid); window.location.href = ''; }}
        value={activeClientId}
      >
        {clientAccounts.map((item:any, index:number) => (
          <option value={item.clientId} key={item.clientId}>
            {clientAccounts[index].companyName}
          </option>
        ))}
      </select>
      )}
      <h2>
        {i18n.t('deviceSecurity.sideBar.hi')}
        ,
        {' '}
        {user && user.firstName}
        ! 👋
      </h2>
      <h2>
        {i18n.t('deviceSecurity.sideBar.welcome')}
      </h2>
      <p>{i18n.t('deviceSecurity.sideBar.p1', { company: user?.companyName ? `, ${user.companyName},` : '' })}</p>
      <p style={{ flexGrow: 1 }}>{i18n.t('deviceSecurity.sideBar.p2')}</p>
      <div style={{ margin: '10px 0' }}>
        <a
          target="_blank"
          rel="noreferrer"
          style={{
            color: '#363636', textAlign: 'left', display: 'block', textDecoration: 'none',
          }}
          href={licensee.termsOfUse}
        >
          {i18n.t('termsOfUse')}
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          style={{
            color: '#363636', textAlign: 'left', display: 'block', textDecoration: 'none',
          }}
          href={licensee.privacyPolicy}
        >
          {i18n.t('privacyPolicy')}

        </a>
      </div>
      <LogOutButton
        data-slug="deviceSecurity.sideBar.logout"
        type="button"
        onClick={() => dispatch(signOut())}
      >
        <FontAwesomeIcon icon={faSignOut} />
        {i18n.t('deviceSecurity.sideBar.logout')}
      </LogOutButton>
    </SideBarWrapper>
  );
};

export default SideBar;


const LogOutButton = styled.button`
border: none;
background-color: transparent;
font-size: 24px;
font-weight: 400;
color #363636;
width: 100%;
max-width: 270px;
text-align: left;
margin-bottom: 30px;
cursor:pointer;

& svg {
  margin-right: 20px;
}
`;

const SideBarWrapper = styled.div`
width: 100%;
max-width: 360px;
background-color: #F5F5F5;
display: flex;
padding: 20px 44px;
flex-direction: column;
@media screen and (max-width: 1000px) {
  z-index:10;
  position:fixed;
  padding-top: 80px;
  top:0;
  bottom:0;
  transform: translateX(-100%);
  transition: transform 1s ease 0s;
  &.show {
    transform: translateX(0%);
    transition: transform 1s ease 0s;
  }
}

h2 {
  color:#3580F9;
  font-size: 32px;
  font-weight: 800;
  max-width: 270px;
  width: 100%;
}
p {
  font-size: 20px;
  font-weight: 500;
  color: #363636;
  max-width: 270px;
}

.sidemenu {
  display: flex;
  flex-direction: column; 
  margin-top: 28px;
  height: 100%;
}

.sidemenu > div:nth-last-child(2) {
  flex-grow: 1;
}

.completed svg {
  display: inline-block;
  font-size: 26px;
  color: rgb(53, 128, 249);
  margin-right: 26px; 
}

.completed svg.shown {
  display: inline-block;
}

.completed svg.notShown {
  display: none;
}

.completed h3 {
  line-height 18px;
  font-size: 21px;
  font-weight: 400;
  color: rgb(53, 128, 249);
  display: inline-block;
  margin: 0;
}

.current svg {
  display: inline-block;
  font-size: 26px;
  color: rgb(53, 128, 249);
  margin-right: 26px; 
}

.current, .next, .completed {
  margin-bottom: 28px;
}

.current svg.shown {
  display: none;
}

.current svg.notShown {
  display: inline-block;
}

.current h3 {
  line-height: 18px;
  font-size: 21px;
  font-weight: 900;
  color: rgb(53, 128, 249);
  display: inline-block;
  margin: 0;
}

.next svg {
  display: inline-block;
  font-size: 26px;
  color: rgb(151, 151, 151);
  margin-right: 26px; 
}

.next svg.shown {
  display: none;
}

.next svg.notShown {
  display: inline-block;
}

.next h3 {
  line-height: 18px;
  font-size: 21px;
  font-weight: 400;
  color: rgb(168, 168, 168);
  display: inline-block;
  margin: 0;
}
`;
