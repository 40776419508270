import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  faCheck, faExclamationTriangle, faStar, faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { faRedoAlt } from '@fortawesome/pro-regular-svg-icons';

import { useTranslation } from '../../i18n';

const ResultWrapper = styled.div`
text-align: center;
color: #444444;

& h2 {
  font-size: ${(props) => (props.grade >= 0.75 ? '45px' : '45px')};
  font-weight: 800;
  color: ${(props) => (props.grade >= 0.75 ? '#3ED290' : '')};
  color: ${(props) => ((props.grade < 0.75 && props.grade >= 0.5) ? '#FFB800' : '')};
  color: ${(props) => (props.grade < 0.5 ? '#FA5B5B' : '')};
  max-width: 1100px;
  margin: 50px auto 0 auto;
}

& svg {
  font-size: 156px;
  color: ${(props) => (props.grade >= 0.75 ? '#3ED290' : '')};
  color: ${(props) => ((props.grade < 0.75 && props.grade >= 0.5) ? '#FFB800' : '')};
  color: ${(props) => (props.grade < 0.5 ? '#FA5B5B' : '')};
}
& .scoreWrapper {
  background-color: ${(props) => (props.grade >= 0.75 ? '#3ED290' : '')};
  background-color: ${(props) => ((props.grade < 0.75 && props.grade >= 0.5) ? '#FFB800' : '')};
  background-color: ${(props) => (props.grade < 0.5 ? '#FA5B5B' : '')};
  width:100%;
  max-width:650px;
  border-radius: 2em;
  color: white;
  font-size: 30px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin: 85px 0;
}

& .scoreWrapper span {
  font-size: 100px;
  margin: 0 20px;
}

& p {
  font-size: 30px;
  max-width: 950px;
  margin: 20px auto;
}
`;

const Button = styled.button`
text-align: center;
width: 100%;
max-width: 600px;
border-radius: 4px;
background-color: #bc1224;
font-size: 30px;
font-weight: 700;
color: white;
padding: 24px 34px;
border:none;
margin-top: 60px;
cursor: pointer;

 & svg {
   font-size: 0px;
   color: white;
   margin-right: 0px;
   display: none;
 }
`;

const calculateResults = (stats) => {
  let correct = 0;
  let total = 0;
  stats.forEach((item) => {
    correct += Object.keys(item.found).length;
    total += item.total;
  });

  return { correct, total };
};

const QuizResults = ({ stats }) => {
  const score = calculateResults(stats);
  const grade = score.correct / score.total;

  let Result = (<SemiPassed score={score} />);
  if (grade >= 0.75) Result = (<SuccessResults score={score} />);
  if (grade < 0.5) Result = (<Failed score={score} />);

  return (
    <ResultWrapper grade={grade}>
      {Result}
    </ResultWrapper>
  );
};

const SuccessResults = ({ score }) => {
  const i18n = useTranslation();
  const [successfulSlide, setSuccessfulSlide] = useState(0);
  if (successfulSlide === 0) {
    return (
      <>
        <FontAwesomeIcon icon={faCheck} />
        <h2>
          {i18n.t('quiz.congratulations')}
        </h2>
        <div style={{ fontSize: '30px' }}>
          {i18n.t('quiz.goodScore')}
        </div>
        <div className="scoreWrapper">
          {i18n.t('quiz.youScored')}
          <span>
            {score.correct}
          </span>
          /
          {score.total}
        </div>
        <p>
          {i18n.t('quiz.weWillInformAdminForResults')}
        </p>
        <p>
          {i18n.t('quiz.exitTraining')}
        </p>
        <Button style={{ fontSize: '22px', textAlign: 'center', maxWidth: '220px' }} onClick={() => setSuccessfulSlide(1)}>
          {i18n.t('next')}
        </Button>
      </>
    );
  }
  if (successfulSlide === 1) {
    return (
      <>
        <div className="tutorialContent">
          <h1 style={{ textAlign: 'center', fontSize: '40px' }}>
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#BC1224', fontSize: '60px', marginRight: '20px' }} />
            {' '}
            {i18n.t('tutorial.remember')}
          </h1>
          <div className="flexRow" style={{ marginTop: '80px', marginLeft: '40px', justifyContent: 'center' }}>
            <div className="rules">
              <h3>1</h3>
            </div>
            <div className="textHolder">
              <h2 style={{
                marginTop: '0px', marginBottom: '0px', color: '#454545', fontSize: '30px',
              }}
              >
                {i18n.t('tutorial.preventPhishingAttacksStep1')}
              </h2>
            </div>
          </div>
          <div
            className="flexRow"
            style={{
              marginBottom: '80px', marginTop: '20px', marginLeft: '40px', justifyContent: 'center', color: '#454545', fontSize: '30px',
            }}
          >
            <div className="rules">
              <h3>2</h3>
            </div>
            <div className="textHolder">
              <h2 style={{
                marginTop: '0px', marginBottom: '0px', color: '#454545', fontSize: '30px',
              }}
              >
                {i18n.t('tutorial.preventPhishingAttacksStep2')}
              </h2>
            </div>
          </div>
          <p className="wrapsNote" style={{ marginBotom: '60px', fontSize: '30px', maxWidth: '1200px' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.rememberP1') }} />
          <p className="wrapsNote" style={{ marginBotom: '60px', fontSize: '30px', maxWidth: '1200px' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.rememberP2') }} />
          <p className="wrapsNote" style={{ marginBotom: '60px', fontSize: '30px', maxWidth: '1200px' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.rememberP3') }} />
        </div>
        {i18n.getLanguage() === 'en' && (
        <Button onClick={() => setSuccessfulSlide(2)} style={{ fontSize: '22px', textAlign: 'center', maxWidth: '220px' }}>
          {i18n.t('next')}
        </Button>
        )}
      </>
    );
  }
  if (successfulSlide === 2) {
    return (
      <>
        <div className="tutorialContent">
          <h1 style={{ textAlign: 'left', fontSize: '40px' }}>
            {i18n.t('tutorial.otherUsefulResources')}
          </h1>
          <p
            className="wrapsNote"
            style={{
              marginBottom: '40px', fontSize: '20px', maxWidth: '1200px', textAlign: 'left',
            }}
          >
            {i18n.t('tutorial.additionalAdvice')}
          </p>
          <p
            className="wrapsNote"
            style={{
              marginBottom: '40px',
              fontSize: '20px',
              maxWidth: '1200px',
              textAlign: 'left',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {i18n.t('tutorial.r1')}
            <img src={i18n.t('tutorial.r1Image')} style={{ width: '200px' }} />
          </p>
          <p
            className="wrapsNote"
            style={{
              marginBottom: '40px', fontSize: '20px', maxWidth: '1200px', textAlign: 'left',
            }}
          >
            {i18n.t('tutorial.r2')}
            <a href={i18n.t('tutorial.r2Url')}>{i18n.t('tutorial.r2Url')}</a>
          </p>
          <p
            className="wrapsNote"
            style={{
              marginBottom: '40px', fontSize: '20px', maxWidth: '1200px', textAlign: 'left',
            }}
          >
            {i18n.t('tutorial.r3')}
            <a href={i18n.t('tutorial.r3Url')}>{i18n.t('tutorial.r3Url')}</a>
          </p>
          <p
            className="wrapsNote"
            style={{
              marginBottom: '40px', fontSize: '20px', maxWidth: '1200px', textAlign: 'left',
            }}
          >
            <FontAwesomeIcon icon={faStar} style={{ color: '#BC1224', marginRight: '5px', fontSize: '24px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#BC1224', marginRight: '5px', fontSize: '24px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#BC1224', marginRight: '5px', fontSize: '24px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#BC1224', marginRight: '5px', fontSize: '24px' }} />
            <FontAwesomeIcon icon={faStar} style={{ color: '#BC1224', marginRight: '40px', fontSize: '24px' }} />
            <span style={{ fontSize: '20px', color: '#454545', fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.trustPilot') }} />
          </p>
        </div>
      </>
    );
  }
};

const SemiPassed = ({ score }) => {
  const i18n = useTranslation();
  const history = useNavigate();
  return (
    <>
      <FontAwesomeIcon icon={faCheck} />
      <h2>
        {i18n.t('quiz.passedButTryAgain')}
      </h2>
      <div className="scoreWrapper">
        {i18n.t('quiz.youScored')}
        <span>
          {score.correct}
        </span>
        /
        {score.total}
      </div>
      <p>
        {i18n.t('quiz.passedButTryAgain')}
      </p>
      <Button onClick={() => history('../tutorial')}>
        <FontAwesomeIcon icon={faRedoAlt} />
        {i18n.t('quiz.restartTraining')}
      </Button>
    </>
  );
};

const Failed = ({ score }) => {
  const i18n = useTranslation();
  const history = useNavigate();
  return (
    <>
      <FontAwesomeIcon icon={faTimes} />
      <h2>
        {i18n.t('quiz.failedTest')}
      </h2>
      <div className="scoreWrapper">
        {i18n.t('quiz.youScored')}
        <span>
          {score.correct}
        </span>
        /
        {score.total}
      </div>
      <p>
        {i18n.t('quiz.tryAgain')}
      </p>
      <Button onClick={() => history('../tutorial')}>
        <FontAwesomeIcon icon={faRedoAlt} />
        {i18n.t('quiz.restartTraining')}
      </Button>
    </>
  );
};

export default QuizResults;
