export const AUTH_USER = 'AUTH_USER';
export const INITIATE_FIREBASE = 'INITIATE_FIREBASE';
export const WATCH_FIRESTORE = 'WATCH_FIRESTORE';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN = 'SIGN_IN';
export const GET_MONITORED_DOMAINS = 'GET_MONITORED_DOMAINS';
export const ADD_EMAIL_RESULTS = 'ADD_EMAIL_RESULTS';
export const SHOW_POP_UP = 'SHOW_POP_UP';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REGISTER_DOMAIN_LISTENER = 'REGISTER_DOMAIN_LISTENER';
export const REGISTER_CLIENT_LISTENER = 'REGISTER_CLIENT_LISTENER';
export const REMOVE_EXPLICIT_EMAIL_RESULTS = 'REMOVE_EXPLICIT_EMAIL_RESULTS';
export const REMOVE_ALL_EMAILS_RESULTS = 'REMOVE_ALL_EMAILS_RESULTS';
export const SET_DOMAIN_SCAN_IN_PROGESS = 'SET_DOMAIN_SCAN_IN_PROGESS';
export const REGISTER_SCAN_IN_PROGRESS_LISTENER = 'REGISTER_SCAN_IN_PROGRESS_LISTENER';
export const GET_MONITORED_SUPPLIERS = 'GET_MONITORED_SUPPLIERS';
export const REGISTER_SUPPLIER_LISTENER = 'REGISTER_SUPPLIER_LISTENER';
export const REMOVE_EXPLICIT_SUPPLIER_RESULTS = 'REMOVE_EXPLICIT_SUPPLIER_RESULTS';
export const FINISH_TUTORIAL = 'FINISH_TUTORIAL';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_ACTIONS = 'GET_ACTIONS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_LICENSEE = 'GET_LICENSEE';
export const GET_PARTNER = 'GET_PARTNER';
export const REGISTER_ACTIVE_CLIENT_LISTENER = 'REGISTER_ACTIVE_CLIENT_LISTENER';
export const GET_DEVICES = 'GET_DEVICES';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';

export default AUTH_USER;
