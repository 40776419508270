import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/pro-light-svg-icons';

import { useTranslation } from '../../i18n';

const Progress = styled.div`
color: #bc1224;
font-size: 24px;
font-weight: 900;
display: flex;
line-height: 27px;
filter: blur(0px) !important;
`;

const Finish = styled.div`
  min-width: 150px;
  ${(props) => (props.progress !== 1 ? 'color:#EAECEF' : '')}
`;

const Question = styled.div`
white-space: nowrap;

`;

const ProgressBar = styled.div`
margin: 0 40px;
background:#EAECEF;
height: 27px;
width:100%;
position: relative;
border-radius: 4px;
overflow: hidden;

&:before {
  content: ' ';
  height: 100%;
  display: inline-block;
  background: #bc1224;
  position: absolute;
  top: 0;
  left: 0;
  ${(props) => `width: ${100 * props.progress}%;`}
}
`;

const ProgressWrapper = ({ question, progress }) => {
  const i18n = useTranslation();
  return (
    <Progress>
      <Question>
        {i18n.t('quiz.question')}
        &nbsp;
        &nbsp;
        {question}
      </Question>
      <ProgressBar progress={progress} />
      <Finish progress={progress}>
        {i18n.t('finish')}
        &nbsp;
        &nbsp;
        {progress === 1 && <FontAwesomeIcon icon={faFlagCheckered} />}
      </Finish>
    </Progress>
  );
};

export default ProgressWrapper;
