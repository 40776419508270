import firebase, { firestore } from '../../firebase';
import { ErrorKey } from '../../track';
import { GET_DEVICES, UPDATE_DEVICES, UPDATE_USER } from './types';

export const getDevices = () => (dispatch, getState) => {
  const { user } = getState();
  if (!user || !user.hashedEmail || !user.clientId) return;
  firestore.collection('clients').doc(user.clientId).collection('trainings')
    .where('hashedEmail', '==', user.hashedEmail)
    .where('training', '==', 'deviceSurvey')
    .orderBy('lastUpdated', 'desc')
    .limit(1)
    .onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        const devices = doc.data().result;
        dispatch({
          type: GET_DEVICES,
          payload: [...devices],
        });
      });
    });
};

const getDeviceSurvey = (user) => firestore.collection('clients').doc(user.clientId).collection('trainings')
  .where('hashedEmail', '==', user.hashedEmail)
  .where('training', '==', 'deviceSurvey')
  .orderBy('lastUpdated', 'desc')
  .limit(1)
  .get()
  .then((query) => {
    let doc = null;
    query.forEach((item) => { doc = item.id; });
    return doc;
  });

export const updateDevice = (device, index) => async (dispatch, getState) => {
  const { user } = getState();
  if (!user || (!user.clientId)) return false;
  const { devices } = getState();
  const updatedDevices = [...devices];
  updatedDevices[index] = device;
  const surveyId = await getDeviceSurvey(user);
  const updated = firestore.collection('clients').doc(user.clientId).collection('trainings')
    .doc(surveyId)
    .update({
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      result: updatedDevices,
    })
    .then(() => {
      dispatch({
        type: UPDATE_DEVICES,
        payload: [...updatedDevices],
      });
      return true;
    })
    .catch((e) => {
      ErrorKey('error.update.device', 'error.update.device');
      return false;
    });
  return updated;
};

export const deleteDevice = (device, index) => async (dispatch, getState) => {
  const { user } = getState();
  if (!user || (!user.clientId)) return false;
  const { devices } = getState();
  const updatedDevices = [...devices];
  updatedDevices.splice(index, 1);
  const surveyId = await getDeviceSurvey(user);
  const deleted = firestore.collection('clients').doc(user.clientId).collection('trainings')
    .doc(surveyId)
    .update({
      result: updatedDevices,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      dispatch({
        type: UPDATE_DEVICES,
        payload: [...updatedDevices],
      });
      return true;
    })
    .catch((e) => {
      ErrorKey('error.delete.device', 'error.delete.device');
      return false;
    });
  return deleted;
};

export const addDevice = (device) => async (dispatch, getState) => {
  const { user } = getState();
  if (!user || (!user.clientId)) return false;
  const { devices } = getState();
  const updatedDevices = devices ? [...devices] : [];
  updatedDevices.push(device);
  let added = false;
  if (!devices) {
    added = firestore.collection('clients').doc(user.clientId).collection('trainings')
      .add({
        created: firebase.firestore.FieldValue.serverTimestamp(),
        opened: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        training: 'deviceSurvey',
        result: updatedDevices,
        hashedEmail: user.hashedEmail,
      })
      .then((docRef) => {
        dispatch({
          type: UPDATE_DEVICES,
          payload: [...updatedDevices],
        });
        firestore.collection('users').doc(user.uid)
          .update({ deviceSurveyDoc: docRef.id });
        dispatch({
          type: UPDATE_USER,
          payload: { ...user, deviceSurveyDoc: docRef.id },
        });
        return true;
      })
      .catch((e) => {
        ErrorKey('error.add.device', 'error.add.device');
        return false;
      });
  } else {
    const surveyId = await getDeviceSurvey(user);
    added = firestore.collection('clients').doc(user.clientId).collection('trainings')
      .doc(surveyId)
      .update({
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        result: updatedDevices,
      })
      .then(() => {
        dispatch({
          type: UPDATE_DEVICES,
          payload: [...updatedDevices],
        });
        return true;
      })
      .catch((e) => {
        ErrorKey('error.add.device', 'error.add.device');
        return false;
      });
  }
  return added;
};
