import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../../i18n';


const Question1Wrapper = styled.div`
width: 100%;
max-width: 956px;
margin: 0 auto;
font-family: 'Poppins', sans-serif;
`;


const Question2 = (props) => {
  const i18n = useTranslation();
  const {
    stats, setStats, currentQuestion, submiting,
  } = props;

  useEffect(() => {
    const firstTime = {
      found: { }, total: 3, type: 'multipleChoice', clicks: 0,
    };
    stats.push(firstTime);
    const newStats = [...stats];
    setStats(newStats);
  }, []);

  const answers = [
    { slug: 'deleteEmail', isCorrect: true },
    { slug: 'forwardToColleague' },
    { slug: 'reply' },
    { slug: 'dontClick', isCorrect: true },
    { slug: 'reportIT', isCorrect: true },
  ];

  const [checkedAnswers, setCheckedAnswers] = useState({});

  const updateStats = (el, bool, index) => {
    const newStats = [...stats];
    if (!answers[index].isCorrect) return;
    if (bool) {
      newStats[currentQuestion] = {
        ...newStats[currentQuestion],
        clicks: stats[currentQuestion].clicks + 1,
        found: {
          ...stats[currentQuestion].found, [el]: true,
        },
      };
    } else {
      newStats[currentQuestion] = {
        ...newStats[currentQuestion],
        clicks: stats[currentQuestion].clicks + 1,
        found: {
          ...stats[currentQuestion].found,
        },
      };
      delete newStats[currentQuestion].found[el];
    }
    setStats(newStats);
  };

  const onCheck = (e, index) => {
    if (submiting) return;
    if (e && e.target) {
      if (e.target.checked) {
        setCheckedAnswers({ ...checkedAnswers, [e.target.id]: true });
        updateStats(e.target.id, true, index);
      } else {
        const newCheckedAnswers = { ...checkedAnswers };
        delete newCheckedAnswers[e.target.id];
        setCheckedAnswers(newCheckedAnswers);
        updateStats(e.target.id, false, index);
      }
    }
  };

  let selected = 0;
  if (checkedAnswers && Object.keys(checkedAnswers)) {
    selected = Object.keys(checkedAnswers).length;
  }
  return (
    <Question1Wrapper>
      <AnswerWrapper>
        {answers.map((item, index) => (
          <Answer
            checked={checkedAnswers[item.slug]}
            key={`${item.slug + index}`}
            disabled={stats[currentQuestion] && selected === stats[currentQuestion].total
              && !checkedAnswers[item.slug]}
          >
            {!submiting && (
            <label
              disabled={stats[currentQuestion] && selected === stats[currentQuestion].total
         && !checkedAnswers[item.slug]}
            >
              {!submiting && (
              <>
                {!checkedAnswers[item.slug] && <FontAwesomeIcon icon={faCircle} style={{ marginRight: '40px' }} />}
                {checkedAnswers[item.slug] && <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '40px' }} />}
              </>
              )}
              {(submiting && item.isCorrect) && (
              <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '40px', color: 'white' }} />
              )}
              {(submiting && !item.isCorrect) && (
              <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '40px', color: 'white' }} />
              )}
              {i18n.t(`quiz.q2.${item.slug}`)}
              <input
                disabled={stats[currentQuestion] && selected === stats[currentQuestion].total
               && !checkedAnswers[item.slug]}
                id={item.slug}
                type="checkbox"
                style={{ display: 'none' }}
                onChange={(e) => onCheck(e, index)}
              />
            </label>
            )}
            {submiting && (
            <label
              style={{ backgroundColor: item.isCorrect ? '#4AB52C' : '#E36666', color: 'white' }}
              disabled={stats[currentQuestion] && selected === stats[currentQuestion].total
         && !checkedAnswers[item.slug]}
            >
              {!submiting && (
              <>
                {!checkedAnswers[item.slug] && <FontAwesomeIcon icon={faCircle} style={{ marginRight: '40px' }} />}
                {checkedAnswers[item.slug] && <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '40px' }} />}
              </>
              )}
              {(submiting && item.isCorrect) && (
              <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '40px', color: 'white' }} />
              )}
              {(submiting && !item.isCorrect) && (
              <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '40px', color: 'white' }} />
              )}
              {i18n.t(`quiz.q2.${item.slug}`)}
              <input
                disabled={stats[currentQuestion] && selected === stats[currentQuestion].total
               && !checkedAnswers[item.slug]}
                id={item.slug}
                type="checkbox"
                style={{ display: 'none' }}
                onChange={(e) => onCheck(e, index)}
              />
            </label>
            )}
          </Answer>
        ))}
      </AnswerWrapper>
    </Question1Wrapper>
  );
};

const AnswerWrapper = styled.ul`
width: 100%;
max-width: 800px;
margin: 0 auto;
list-style: none;
`;

const Answer = styled.li`
width:100%;
border-radius: 4px;
box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
box-sizing: border-box;
${(props) => (props.checked ? 'background-color: #bc1224;' : '')}
color:${(props) => (props.checked ? ' white' : '#bc1224')};
${(props) => (props.disabled ? 'opacity: 0.4;' : '')}
font-size: 30px;
font-weight: 500;
cursor: pointer;
margin-bottom: 29px;

& label {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
}
`;

export default Question2;
