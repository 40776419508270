import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUndo, faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from '../i18n';

function QuizStart() {
  const i18n = useTranslation();
  return (
    <div className="tutorialWrapper">
      <div className="box">
        <div className="phishingStart">
          <FontAwesomeIcon icon={faQuestionCircle} color="#BC1224" size="4x" />
          <h1>{i18n.t('startQuiz.title')}</h1>
          <p>{i18n.t('startQuiz.paragraph')}</p>
          <div className="flexRow">
            <Link to="/phising/tutorial" style={{ textDecoration: 'none' }}>
              <button className="restartButton" type="button">
                <FontAwesomeIcon icon={faUndo} color="#282c34" size="1x" style={{ paddingRight: '10px' }} />
                {i18n.t('startQuiz.goTraining')}
              </button>
            </Link>
            <Link to="/phising/quiz" style={{ textDecoration: 'none' }}>
              <button className="quizButton" type="button">
                <FontAwesomeIcon icon={faPlay} color="white" size="1x" style={{ marginRight: '20px' }} />
                {i18n.t('startQuiz.startQuiz')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizStart;
