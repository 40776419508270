import React from 'react';

export const User = class {
  setParams(params) {
    this.userId = params.userId;
    this.clientId = params.clientId;
    this.firstName = params.firstName;
    this.lastName = params.lastName;
    this.companyName = params.companyName;
  }
};

export const UserContext = React.createContext(null);
