import ReactGA from 'react-ga';
import axios from 'axios';
import { auth, googleProvider } from '../../firebase';
import { AUTH_USER, SIGN_OUT, SIGN_IN } from './types';
import { getUser } from './user.actions.ts';
import { ErrorKey, SuccessKey, TrackInfo } from '../../track';

const getUrlLanguage = () => {
  const path = window.location.pathname;
  if (path.length === 3 && path === '/es') return 'es';
  if (path.length === 3 && path === '/de') return 'de';
  if (path.length === 3 && path === '/en') return 'en';
  if (path.length > 3 && path.slice(0, 4) === '/es/') return 'es';
  if (path.length > 3 && path.slice(0, 4) === '/de/') return 'de';
  if (path.length > 3 && path.slice(0, 4) === '/en/') return 'en';
  return '';
};

export const authUser = (needAuth) => (dispatch) => {
  const wasLogged = auth.currentUser && true;
  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch(getUser(user.uid));
      dispatch({
        type: AUTH_USER,
        payload: auth,
      });
    } else {
      dispatch({
        type: AUTH_USER,
        payload: auth,
      });
      // he logged-out, move him to sign-in
      if (wasLogged || needAuth) {
        window.location.href = `/${getUrlLanguage()}/?redirect=${window.location.href}`;
      }
    }
  });
};

export const signOut = () => (dispatch) => {
  auth.signOut().then(() => {
    dispatch({
      type: SIGN_OUT,
      payload: auth,
    });
    window.location.href = `/${getUrlLanguage()}/`;
  });
};

export const signInWithEmailAndPassword = (email, pass, redirect) => (dispatch) => {
  ReactGA.event({
    category: 'Authentication',
    action: 'Sign in',
    label: 'Email/Password',
  });
  auth.signInWithEmailAndPassword(
    email,
    pass,
  )
    .then(() => {
      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href = `/${getUrlLanguage()}/`;
      }
    })
    .catch((e) => {
      console.log(e);
      if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found') {
        ErrorKey('authentication.wrongPasswordOrAccount');
      } else {
        ErrorKey('authentication.errorWithAuthentication');
      }
    });
};

export const signInWithGoogle = (redirect) => (dispatch) => {
  ReactGA.event({
    category: 'Authentication',
    action: 'Sign in',
    label: 'Gmail',
  });
  auth.signInWithPopup(googleProvider).then((result) => {
    if (redirect) {
      window.location.href = redirect;
    } else {
      window.location.href = `/${getUrlLanguage()}/`;
    }
  }).catch((error) => {
    console.log(error);
  });
};

export const signInWithEmailLink = (email, pass, redirect) => (dispatch) => {
  ReactGA.event({
    category: 'Authentication',
    action: 'Sign in',
    label: 'Email/Link',
  });
  // Confirm the link is a sign-in with email link.
  if (auth.isSignInWithEmailLink(`${window.location.origin}/${getUrlLanguage()}`)) {
  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }
    // The client SDK will parse the code from the link for you.
    auth.language = getUrlLanguage();
    auth.signInWithEmailLink(email, `${window.location.origin}/${getUrlLanguage()}`)
      .then((result) => {
      // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      });
  }
/*
  auth.signInWithEmailAndPassword(
    email,
    pass,
  )
    .then(() => {
      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href = '/';
      }
    })
    .catch((e) => {
      console.log(e);
      if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found') {
        ErrorKey('authentication.wrongPasswordOrAccount');
      } else {
        ErrorKey('authentication.errorWithAuthentication');
      } */
};

export const sendSignInEmailLink = (email) => (dispatch) => {
  const actionCodeSettings = {
    url: `${window.location.origin}/${getUrlLanguage()}`,
    handleCodeInApp: true,
    languageCode: getUrlLanguage(),
  };
  auth.languageCode = getUrlLanguage();
  auth.sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
      TrackInfo('signIn.signWithEmailLinkSent', 'signWithEmailLinkSent');
      window.localStorage.setItem('emailForSignIn', email);
    })
    .catch((error) => {
    // Some error occurred, you can inspect the code: error.code
      ErrorKey('error.failedSendEmailLink', 'signWithEmailLinkFail');
    });
};
