import { combineReducers } from 'redux';
import userReducer from './userReducer';
import authReducer from './authReducer';
import licenseeReducer from './licenseeReducer';
import devicesReducer from './devicesReducer';


export default combineReducers({
  auth: authReducer,
  user: userReducer,
  licensee: licenseeReducer,
  devices: devicesReducer,
});
