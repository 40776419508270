import React from 'react';
import styled from '@emotion/styled';

const PopUp:React.FC = ({ children }) => (
  <PopUpWrapper>
    {children}
  </PopUpWrapper>
);

export default PopUp;

const PopUpWrapper = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
top:0;
left:0;
background-color: rgba(0,0,0,0.4);
z-index: 10000;
`;
