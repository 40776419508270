import { GET_LICENSEE } from '../actions/types';
import { i18n } from '../../i18n';

let defaultState = {
  defaultLanguage: 'en',
  privacyPolicy: 'https://bewica.com/privacy-policy',
  termsOfUse: 'https://bewica.com/en/terms-and-conditions',
  email: 'contact@bewica.com',
  phone: '+44 (0) 20 3026 5840',
};

const loadCSS = (licensee) => {
  const cssId = 'whitelabelCSS'; // you could encode the css path itself to generate id..
  if (!document.getElementById(cssId)) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.id = cssId;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `https://storage.googleapis.com/bewica-assets/app/whitelabel_css/${licensee}.css`;
    link.media = 'all';
    head.appendChild(link);
  }
};


if (window.location.hostname.split('.')[0] === 'zurich' || window.location.hostname.split('.')[0] === 'staging-zurich') {
  defaultState = {
    ...defaultState,
    name: 'zurich',
    defaultLanguage: 'de',
    market: 'de',
    impressum: 'https://bewica.com/de-z/zurich-impressum',
    privacyPolicy: 'https://bewica.com/de-z/bewica-datenschutz',
    termsOfUse: 'https://bewica.com/de-z/bewica-agb',
  };
  i18n.changeLanguage(defaultState.defaultLanguage);
  loadCSS('zurich');
}

if (window.location.hostname.split('.')[0] === 'de' || window.location.hostname.split('.')[0] === 'de-staging'
|| window.location.pathname.indexOf('/de/') === 0) {
  defaultState = {
    ...defaultState,
    defaultLanguage: 'de',
    market: 'de',
    impressum: 'https://bewica.com/de/impressum',
    privacyPolicy: 'https://bewica.com/de/datenschutz',
    termsOfUse: 'https://bewica.com/de/agb',
  };
  i18n.changeLanguage(defaultState.defaultLanguage);
}

if (window.location.hostname.split('.')[0] === 'es' || window.location.hostname.split('.')[0] === 'es-staging'
|| window.location.pathname.indexOf('/es/') === 0) {
  defaultState = {
    ...defaultState,
    defaultLanguage: 'es',
    market: 'es',
    privacyPolicy: 'https://bewica.com/es/politica-de-privacidad',
    termsOfUse: 'https://bewica.com/es/terminos-y-condiciones',
    phone: '872 580 342',
  };
  i18n.changeLanguage(defaultState.defaultLanguage);
}

if (window.location.hostname.split('.')[0] === 'fr' || window.location.hostname.split('.')[0] === 'fr-staging') {
  defaultState = {
    ...defaultState,
    defaultLanguage: 'fr',
    market: 'fr',
  };
  i18n.changeLanguage(defaultState.defaultLanguage);
}

if (window.location.hostname.split('.')[0] === 'pl' || window.location.hostname.split('.')[0] === 'pl-staging') {
  defaultState = {
    ...defaultState,
    defaultLanguage: 'pl',
    market: 'pl',
  };
  i18n.changeLanguage(defaultState.defaultLanguage);
}


export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_LICENSEE:
      return action.payload || {};
    default:
      return state;
  }
};
