import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useContext } from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import ReactDOM from 'react-dom';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import Phishing from './PhishingTraining/Welcome';
import Tutorial from './PhishingTraining/Tutorial';
import Quiz from './PhishingTraining/Quiz/index';
import QuizStart from './PhishingTraining/quizStart';
import { I18nContext, I18nContextProvider } from './i18n';
import { User, UserContext } from './user';
import Login from './components/SignIn';
import configureStore from './redux/store';
import history from './history';
import DeviceSecurity from './DeviceSecurity';
import Authentication from './Authentication';
import axiosIntercept from './axiosInterceptors';
import { addClickListener, addInputChangeListener } from './track';

const ChangeLanguageButton = () => {
  const {
    i18n, setI18n, lang, setLang,
  } = useContext(I18nContext);

  const clickIt = () => {
    const langz = i18n.getLanguage() === 'en' ? 'de' : 'en';
    i18n.changeLanguage(langz);
    setLang(langz);
  };
  return (<button onClick={() => clickIt()} type="button">Change Language</button>);
};

ReactGA.initialize(process.env.REACT_APP_GA_API_KEY);
// anonymize IP
ReactGA.ga('set', 'anonymizeIp', true);

// hotjar
hotjar.initialize(process.env.REACT_APP_HOTJAR_API_KEY, 6);

// add bearer authentication token if exists in each axios request
axiosIntercept();

let insuranceState;
const initialState = {};
const store = configureStore(initialState, history);

addClickListener();
addInputChangeListener();

const authRoutes = (
  <>
    <Route path="/device-security" exact element={<DeviceSecurity />} />
    <Route path="/device-security/:action" exact element={<DeviceSecurity />} />
    <Route path="/device-security/:action/:id" exact element={<DeviceSecurity />} />
  </>
);
const publicRoutes = (
  <>
    <Route path="/t/:trainingId/u/:u" exact element={<Phishing />} />
    <Route path="/t/:trainingId/p/:p" exact element={<Phishing />} />
    <Route path="/phishing" exact element={<Phishing />} />
    <Route path="/phishing/tutorial" exact element={<Tutorial />} />
    <Route path="/phishing/quiz" exact element={<Quiz />} />
    <Route path="/phishing/quiz-start" exact element={<QuizStart />} />
    <Route path="/" exact element={<Login />} />
  </>
);
const routing = (
  <Provider store={store}>
    <Router>
      <UserContext.Provider value={new User()}>
        <I18nContextProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Authentication>
              <Routes>
                {authRoutes}
                <Route path=":lang" exact>
                  {authRoutes}
                </Route>
              </Routes>
            </Authentication>
            <Routes>
              {publicRoutes}
              <Route path=":lang">
                {publicRoutes}
              </Route>
            </Routes>

          </Routes>
        </I18nContextProvider>
      </UserContext.Provider>
    </Router>
  </Provider>
);

ReactDOM.render(routing, document.getElementById('root'));
