import axios from 'axios';
import firebase from './firebase';
import { i18n } from './i18n';

const getAccessToken = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();
      return token;
    }
    return null;
  };

  const axiosIntercept = () => {
    axios.interceptors.request.use(async (config) => {
      // if its a request to back-end(contain origin url or a path that stats with /) add auth token
      if (config.url.indexOf(window.location.origin) > -1 || config.url[0] === '/') {
        const token = await getAccessToken();
        config.headers.Authorization = token;
        try {
          const lang = i18n.getLanguage();
          config.headers['App-Language'] = lang;
        } catch (e) {
          console.log(e);
        }
      }

      return config;
    });
    const interceptor = axios.interceptors.response.use(response => response, (error) => {
      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);
      const { firebase } = this.props;
      return firebase.auth().currentUser.getIdToken(true).then((idToken) => {
        error.response.config.headers.Authorization = `${idToken}`;
        return axios(error.response.config);
      }).catch((er) => {
        if (window.location.pathname !== '/two-factor-authentication') window.location.href = '/';

        return Promise.reject(er);
      }).finally(this.axiosIntercepts);
    });
  }

  export default axiosIntercept;