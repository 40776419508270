import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneLaptop } from '@fortawesome/pro-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { UAParser } from 'ua-parser-js';

import DeviceCard from './components/DeviceCard';
import {
  checkIfSimilarDeviceExists,
  ComputerOS, Device, getDetectedDevice, MobileOS, PrimaryBrowser,
} from './helper';
import { i18n } from '../i18n';
import SideBar from './SideBar';

const test:(Device)[] = [{
  deviceType: 'computer',
  ownership: 'personal',
  operatingSystem: 'windows',
  browser: 'chrome',
  antivirus: true,
  firewall: false,
  encryption: true,
  autoUpdateOS: true,
  autoUpdateBrowser: true,
}, {
  deviceType: 'computer',
  ownership: 'personal',
  operatingSystem: 'windows',
  browser: 'chrome',
  antivirus: true,
  firewall: true,
  encryption: true,
  autoUpdateOS: true,
  autoUpdateBrowser: true,
}, {
  deviceType: 'computer',
  ownership: 'company',
  operatingSystem: 'macOS',
  browser: 'edge',
  antivirus: true,
  firewall: true,
  encryption: true,
  autoUpdateOS: true,
}, {
  deviceType: 'mobile',
  ownership: 'personal',
  operatingSystem: 'iOS',
  screenlock: false,
  encryption: true,
  latestOS: true,
}];

type IndexedDevice = { device:Device, index:number};

const YourDevices = () => {
  const history = useNavigate();
  const devices: IndexedDevice[] = useSelector((redux:any) => (redux.devices
    ? redux.devices.map((item:Device, index:number) => ({ device: item, index })) : redux.device));
  useEffect(() => {
  }, [devices]);

  const UA = new UAParser();

  return (
    <>
      <SideBar />
      <YourDevicesWrapper>
        <h1>
          <FontAwesomeIcon icon={faPhoneLaptop} />
          {i18n.t('deviceSecurity.yourDevices.title')}
        </h1>
        <p>
          {i18n.t('deviceSecurity.yourDevices.subTitle')}
        </p>

        {getDetectedDevice(UA) && !checkIfSimilarDeviceExists(getDetectedDevice(UA), devices) && (
        <>
          <h2>
            Auto-detected
          </h2>
          <DeviceCard
            device={getDetectedDevice(UA)}
            autoDetect
            onClick={() => history('add/detected')}
          />
        </>
        )}
        {(devices && devices
          .filter((item:(IndexedDevice)) => item.device.ownership === 'personal').length > 0) && (
          <h2>
            {i18n.t('deviceSecurity.deviceCard.personalDevice')}
          </h2>
        )}
        {devices && devices.filter((item:(IndexedDevice)) => item.device.ownership === 'personal')
          .map((item:(IndexedDevice), index:number) => (
            <DeviceCard
              key={index}
              device={item.device}
              onClick={() => history(`edit/${item.index}`)}
            />
          ))}
        <h2>
          {i18n.t('deviceSecurity.deviceCard.companyDevice')}
        </h2>
        {devices && devices.filter((item:(IndexedDevice)) => item.device.ownership === 'company')
          .map((item:(IndexedDevice), index:number) => (
            <DeviceCard
              key={index}
              device={item.device}
              onClick={() => history(`edit/${item.index}`)}
            />
          ))}
        <AddDeviceButton type="button" data-slug="deviceSecurity.deviceCard.addDevice" onClick={() => history('add')}>
          <FontAwesomeIcon icon={faPlusCircle} />
          {i18n.t('deviceSecurity.deviceCard.addDevice')}
        </AddDeviceButton>
      </YourDevicesWrapper>
    </>
  );
};

export default YourDevices;

const YourDevicesWrapper = styled.div`
width: 100%;
max-width: 900px;
margin: 0 auto;
padding: 0 10px;

h1 {
  color:#3580F9;
}
& h1 svg {
  margin-right: 45px;
}

h2 {
  margin-top: 41px;
  color: #3B3B3B;
  font-weight: 800;
  font-size: 21px;
  margin-bottom:20px;
}
`;

const AddDeviceButton = styled.button`
cursor:pointer;
width: 100%;
max-width: 900px;
background-color: white;
box-shadow: rgba(0,0,0,0.06) 0px 2px 33px 3px;
font-size: 19px;
font-weight: 600;
color: #414141;
border-radius: 33px;
padding: 17px;
border: none;
& > svg {
  margin-right: 20px;
}
`;
