import firebase from '@firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';


const fbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
};

firebase.initializeApp(fbConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export default firebase;
