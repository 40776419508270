import React from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import YourDevices from './YourDevices';
import AddDevice from './AddDevice';

const showSidebar = () => {
  const sidebar = document.getElementById('sidebar');
  sidebar?.classList.toggle('show');
};

const DeviceSecurity = () => {
  const { action, id } = useParams();
  const param = useParams();
  return (
    <div style={{ height: '100%', minHeight: '100vh', backgroundColor: 'white' }}>
      <DeviceSecurityWrapper>
        <div className="mobile">
          <img src="https://storage.googleapis.com/cyberguardian-assets/Training/Phishing%20Training/Assets/Images/Logo-training.svg" width="200" alt="Cyber Guardian" />
          <FontAwesomeIcon icon={faBars} onClick={showSidebar} />
        </div>
        {!action && <YourDevices />}
        {action && <AddDevice />}
      </DeviceSecurityWrapper>
    </div>
  );
};

export default DeviceSecurity;

const DeviceSecurityWrapper = styled.div`
display:flex;
background-color:white;
& .mobile { display:none;}
min-height: 100vh;
@media screen and (max-width: 1000px) {
  min-height: auto;
  flex-wrap: wrap;
  .mobile {
    z-index: 100;
    display: block;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width:100%;

    svg {
      color:#BC1224;
      font-size: 30px;
      margin-right: 10px;
    }
  }
}
`;
