import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faApple, faChrome, faFirefox, faSafari, faWindows, faInternetExplorer, faEdge, faAndroid,
} from '@fortawesome/free-brands-svg-icons';
import {
  faQuestionCircle, faLaptop, faBuilding, faPlus, faSquare, faPhoneLaptop,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faMobileAndroidAlt, faTabletAndroidAlt, faUser, faArrowCircleRight, faThumbsUp, faThumbsDown,
  faHammer, faShieldAlt, faBug, faFireAlt, faTrash, faCheck, faPen, faCheckCircle, faKey, faSyncAlt, faFilePdf, faVideo, faArrowLeft, faCheckSquare, faBolt, faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { UAParser } from 'ua-parser-js';
import Swal from 'sweetalert2';
import {
  Computer, Device, getDetectedDevice, Mobile, Question, questions,
} from './helper';
import { i18n } from '../i18n';
import SideBar from './SideBar';
import { addDevice, deleteDevice, updateDevice } from '../redux/actions/devices.actions';
import IssueDetailsAddDevice from './components/IssueDetailsAddDevice';
import PopUp from './components/PopUp';
import CheckMeasurement from './components/CheckMeasurements';
import RequestPermissionForCompanyDevice from './components/RequestPermissionForCompanyDevice';
import CheckUser from '../img/check-circle-user.svg';
import PopperOnHover from './components/PopperOnHover';

const icon = {
  macOS: faApple,
  iOS: faApple,
  android: faAndroid,
  windows: faWindows,
  chrome: faChrome,
  firefox: faFirefox,
  safari: faSafari,
  IE: faInternetExplorer,
  edge: faEdge,
  other: faQuestionCircle,
  mobile: faMobileAndroidAlt,
  computer: faLaptop,
  tablet: faTabletAndroidAlt,
  personal: faUser,
  company: faBuilding,
  yes: faThumbsUp,
  no: faThumbsDown,
};

const issuesIcons = {
  screenlock: faBug,
  antivirus: faBug,
  firewall: faFireAlt,
  encryption: faKey,
  autoUpdateBrowser: faSyncAlt,
  autoUpdateOS: faSyncAlt,
  latestOS: faSyncAlt,
  latestBrowser: faSyncAlt,
};

// type possibleKey = 'encryption' | 'antivirus' | 'firewall' | 'autoUpdateOS' | 'autoUpdateBrowser' | 'latestOS' | 'screenlock';

const getEditingQuestion = (key: string, deviceType: 'computer' | 'mobile' | 'tablet'):number => {
  if (deviceType === 'computer') {
    switch (key) {
      case 'antivirus':
        return 0;
      case 'firewall':
        return 1;
      case 'encryption':
        return 2;
      case 'autoUpdateOS':
        return 3;
      case 'autoUpdateBrowser':
        return 4;
      default:
        return 0;
    }
  } else {
    switch (key) {
      case 'screenlock':
        return 0;
      case 'encryption':
        return 1;
      case 'latestOS':
        return 2;
      case 'latestBrowser':
        return 3;
      default:
        return 0;
    }
  }
};

const disallowToProceedToStep2 = (device:Computer|Mobile) => {
  if (device.deviceType === 'computer') {
    if (device.operatingSystem && device.ownership && device.browser) {
      return false;
    }
  } else if (device.operatingSystem && device.ownership) {
    return false;
  }
  return true;
};

const getProgress = (device:any) => {
  if (!device) return { section: 0, question: 0 };
  const progress = { section: 0, question: 0 };
  if (device.deviceType && device.operatingSystem && device.ownership
    && device.browser) {
    progress.section = 1;
    progress.question = 0;
  }
  if (device.encryption || device.firewall || device.antivirus || device.autoUpdateBrowser
    || device.autoUpdateOS || device.latestOS) {
    progress.section = 1;
    progress.question = 0;
  }
  if ((Object.keys(device).length > 7 && device.deviceType !== 'computer')
  || (device.deviceType === 'computer' && (Object.keys(device).length > 8))) {
    progress.section = 2;
    progress.question = 0;
  }
  return progress;
};

const AddDevice:React.FC = () => {
  const { id, action } = useParams();
  const devices = useSelector((redux:any) => redux.devices);
  const device = useSelector((redux:any) => (redux.devices ? redux.devices[id] : redux.devices));
  const user = useSelector((redux:any) => redux.user);
  // 0 -> device details , 1 -> device security , 2 -> device review
  const [section, setSection] = useState(device ? getProgress(device).section : 0);
  const [activeIssue, setActiveIssue] = useState('');
  const [question, setQuestion] = useState(device ? getProgress(device).question : 0);
  const [answers, setAnswers] = useState<any>(device || {});
  const history = useNavigate();
  const dispatch:any = useDispatch();
  const [detectedDevice, setDetectedDevice] = useState({});
  const [activeQuestions, setActiveQuestions] = useState([]);

  useEffect(() => {
    if (device) {
      setAnswers(device);
      setSection(getProgress(device).section);
    }
  }, [device]);

  const handleNextQuestion = () => {
    if (answers.deviceType === 'computer' && ((section === 0 && question < 3)
    || (section === 1 && ((question < activeQuestions.length - 1 && answers.operatingSystem !== 'macOS')
    || (question < 3 && answers.operatingSystem === 'macOS'))
    ))) {
      const tmpAnswers = { ...answers };
      if (user?.deviceSecurity?.measures?.computer?.antivirus) {
        tmpAnswers.antivirus = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.firewall) {
        tmpAnswers.firewall = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.autoUpdateOS) {
        tmpAnswers.autoUpdateOS = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.autoUpdateBrowser) {
        tmpAnswers.autoUpdateBrowser = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.encryption) {
        tmpAnswers.encryption = 'byAdmin';
      }
      if (answers.operatingSystem === 'macOS'
      && answers.antivirus === undefined) {
        tmpAnswers.antivirus = true;
      }
      setQuestion(question + 1);
      dispatch(updateDevice({ ...tmpAnswers }, Number(id)));
    } else if (answers.deviceType !== 'computer' && question < 3) {
      const tmpAnswers = answers;
      if (answers.operatingSystem === 'iOS' && answers.screenlock && question === 0) {
        tmpAnswers.encryption = true;
      }
      if (user?.deviceSecurity?.measures?.mobile?.encryption) {
        tmpAnswers.encryption = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.mobile?.latestOS) {
        tmpAnswers.latestOS = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.mobile?.latestBrowser) {
        tmpAnswers.latestBrowser = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.mobile?.screenlock) {
        tmpAnswers.screenlock = 'byAdmin';
      }
      setQuestion(question + 1);
      dispatch(updateDevice({ ...tmpAnswers }, Number(id)));
    } else {
      const tmpAnswers = { ...answers };
      if (answers.deviceType === 'computer') {
        if (user?.deviceSecurity?.measures?.computer?.antivirus) {
          tmpAnswers.antivirus = 'byAdmin';
        }
        if (user?.deviceSecurity?.measures?.computer?.firewall) {
          tmpAnswers.firewall = 'byAdmin';
        }
        if (user?.deviceSecurity?.measures?.computer?.autoUpdateOS) {
          tmpAnswers.autoUpdateOS = 'byAdmin';
        }
        if (user?.deviceSecurity?.measures?.computer?.autoUpdateBrowser) {
          tmpAnswers.autoUpdateBrowser = 'byAdmin';
        }
        if (user?.deviceSecurity?.measures?.computer?.encryption) {
          tmpAnswers.encryption = 'byAdmin';
        }
      } else {
        if (user?.deviceSecurity?.measures?.mobile?.encryption) {
          tmpAnswers.encryption = 'byAdmin';
        }
        if (user?.deviceSecurity?.measures?.mobile?.latestOS) {
          tmpAnswers.latestOS = 'byAdmin';
        }
        if (user?.deviceSecurity?.measures?.mobile?.latestBrowser) {
          tmpAnswers.latestBrowser = 'byAdmin';
        }
        if (user?.deviceSecurity?.measures?.mobile?.screenlock) {
          tmpAnswers.screenlock = 'byAdmin';
        }
      }
      dispatch(updateDevice(tmpAnswers, Number(id)));
      setSection(2);
    }
  };

  const handleClick = (slug:string, answer: string, index:number) => {
    if (slug === 'deviceType' && answer !== answers[slug]) {
      setAnswers({ [slug]: answer });
      setQuestion(question <= index ? index + 1 : question);
    } else if (answer !== 'yes' && answer !== 'no') {
      setAnswers({ ...answers, [slug]: answer });
      setQuestion(question <= index ? index + 1 : question);
    } else if (answer === 'yes') {
      if (slug === 'autoUpdateOS') {
        if (answers.browser === 'IE' && answers.operatingSystem === 'windows') {
          setAnswers({ ...answers, [slug]: true, autoUpdateBrowser: true });
          return;
        }
        if (answers.browser === 'safari' && answers.operatingSystem === 'macOS') {
          setAnswers({ ...answers, [slug]: true, autoUpdateBrowser: true });
          return;
        }
      }
      if (slug === 'screenlock' && answers.operatingSystem === 'iOS') {
        setAnswers({ ...answers, [slug]: true, encryption: true });
        return;
      }
      setAnswers({ ...answers, [slug]: true });
    } else {
      if (slug === 'autoUpdateOS') {
        if (answers.browser === 'IE' && answers.operatingSystem === 'windows') {
          setAnswers({ ...answers, [slug]: false, autoUpdateBrowser: false });
          return;
        }
        if (answers.browser === 'safari' && answers.operatingSystem === 'macOS') {
          setAnswers({ ...answers, [slug]: false, autoUpdateBrowser: false });
          return;
        }
      }
      if (slug === 'screenlock' && answers.operatingSystem === 'iOS') {
        setAnswers({ ...answers, [slug]: false, encryption: false });
        return;
      }
      setAnswers({ ...answers, [slug]: false });
    }
  };
  useEffect(() => {
    if (id === 'detected') {
      const UA = new UAParser();
      const detected = getDetectedDevice(UA);
      setAnswers({ ...answers, ...detected });
      setQuestion(4);
      setDetectedDevice({ ...detected });
    }
  }, []);

  const handleProceedToSecurity = () => {
    let oldsize = 0;
    if (devices) oldsize = devices.length;
    const tmpAnswers = { ...answers };
    if (answers.deviceType === 'computer') {
      if (user?.deviceSecurity?.measures?.computer?.antivirus) {
        tmpAnswers.antivirus = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.firewall) {
        tmpAnswers.firewall = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.autoUpdateOS) {
        tmpAnswers.autoUpdateOS = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.autoUpdateBrowser) {
        tmpAnswers.autoUpdateBrowser = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.computer?.encryption) {
        tmpAnswers.encryption = 'byAdmin';
      }
    } else {
      if (user?.deviceSecurity?.measures?.mobile?.encryption) {
        tmpAnswers.encryption = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.mobile?.latestOS) {
        tmpAnswers.latestOS = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.mobile?.latestBrowser) {
        tmpAnswers.latestBrowser = 'byAdmin';
      }
      if (user?.deviceSecurity?.measures?.mobile?.screenlock) {
        tmpAnswers.screenlock = 'byAdmin';
      }
    }

    if (action === 'add') {
      dispatch(addDevice(tmpAnswers)).then(() => {
        setSection(1);
        history(`/${i18n.getLanguage()}/device-security/edit/${oldsize}`);
      });
    } else {
      dispatch(updateDevice(tmpAnswers, Number(id))).then(() => {
        setSection(2);
      });
    }
  };
  const [warned, setWarned] = useState(false);
  useEffect(() => {
    if (!warned) {
      if (answers.deviceType === 'computer' && answers.operatingSystem === 'other') {
        setWarned(true);
        Swal.fire('', i18n.t('deviceSecurity.deviceQuestion.warningNoTips'));
      }
    }
  });

  useEffect(() => {
    if (!device && section === 1) setQuestion(0);
  }, [section]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [needCompanyPermission, setNeedCompanyPersmission] = useState(false);

  useEffect(() => {
    setActiveQuestions(questions[answers.deviceType || 'computer']
      .filter((item:Question) => (answers.operatingSystem !== 'macOS'
|| (answers.operatingSystem === 'macOS' && item.slug !== 'antivirus')))
      .filter((item:Question, index:number) => ((answers.deviceType === 'computer' && index > 3)
  || (answers.deviceType !== 'computer' && index > 3)))
      .filter((item:Question) => (item.slug !== 'encryption' || answers.operatingSystem !== 'iOS'))
      .filter((item:Question, index:number) => {
        if (answers.deviceType === 'computer') {
          if (user?.deviceSecurity?.measures?.computer) {
            if (Object.keys(user?.deviceSecurity?.measures?.computer).filter((key:string) => user?.deviceSecurity?.measures?.computer[key]).indexOf(item.slug) === -1) {
              return true;
            }
            return false;
          }
        } else if (user?.deviceSecurity?.measures?.mobile) {
          if (Object.keys(user?.deviceSecurity?.measures?.mobile).filter((key:string) => user?.deviceSecurity?.measures?.mobile[key]).indexOf(item.slug) === -1) {
            return true;
          }
          return false;
        }

        return true;
      }));
  }, [question]);

  return (
    <>
      <SideBar mode="device" section={section} answers={answers} />
      {needCompanyPermission && (
      <PopUp>
        <RequestPermissionForCompanyDevice accept={() => { setSection(3); setNeedCompanyPersmission(false); }} cancel={() => setNeedCompanyPersmission(false)} />
      </PopUp>
      )}
      { showPopUp && (
        <PopUp>
          <CheckMeasurement answers={answers} activeIssue={activeIssue} close={() => setShowPopUp(false)} />
        </PopUp>
      )}
      <AddDeviceWrapper>
        {section === 0 && (
        <>
          <h1>
            {i18n.t('deviceSecurity.deviceQuestion.deviceDetailsTitle')}
          </h1>
          <p>
            {i18n.t('deviceSecurity.deviceQuestion.deviceDetailsSub')}
          </p>
        </>
        )}
        {section === 1 && (
        <>
          <h1>
            {i18n.t('deviceSecurity.deviceQuestion.deviceSecurityTitle')}
          </h1>
          <p>
            {i18n.t('deviceSecurity.deviceQuestion.deviceSecuritySub')}
          </p>
        </>
        )}
        {section === 2 && (
        <>
          <h1>
            {i18n.t('deviceSecurity.deviceQuestion.deviceSummaryTitle')}
          </h1>
          <p>
            {i18n.t('deviceSecurity.deviceQuestion.deviceSummarySub')}
          </p>
        </>
        )}
        {section === 0
        && questions[answers.deviceType || 'computer']
          .filter((item:Question, index:number) => (index <= question
            && ((answers.deviceType === 'computer' && index < 4)
            || (answers.deviceType !== 'computer' && index < 4))))
          .map((item:Question, index:number) => (
            <QuestionWrapper
              className={(action === 'edit'
            && ['deviceType', 'operatingSystem'].indexOf(item.slug) > -1) ? 'disabled' : ''}
              style={{ maxWidth: item.slug !== 'browser' ? '450px' : '900px' }}
            >
              <h2>
                {index + 1}
                .
                {' '}
                {i18n.t(`deviceSecurity.deviceQuestion.${item.titleSlug}`)}
              </h2>
              <br />
              <div style={{ display: 'inline-block', width: '100%' }}>
                {item.answers
                  .filter((el) => (item.slug !== 'browser' || ((answers.operatingSystem === 'windows' && el !== 'safari')
                  || (answers.operatingSystem === 'macOS' && el !== 'IE') || (answers.operatingSystem === 'android' && el !== 'safari')
                  || (answers.operatingSystem === 'iOS'))))
                  .map((answer:string) => (
                    <label htmlFor={`${item.slug}-${answer}`}>
                      <IconWrapper
                        className={answers[item.slug] === undefined ? ''
                          : answers[item.slug] === answer ? 'selected' : 'notSelected'}
                      >
                        {((detectedDevice[item.slug] && detectedDevice[item.slug] === answers[item.slug]) && detectedDevice[item.slug] === answer) && (
                        <div style={{
                          borderRadius: '50%',
                          width: '35px',
                          height: '35px',
                          padding: '8px',
                          textAlign: 'center',
                          backgroundColor: '#7BAEFF',
                          color: 'white',
                          position: 'absolute',
                          right: '-17px',
                          top: '-17px',
                        }}
                        >
                          <FontAwesomeIcon icon={faBolt} className="thunder" />
                        </div>
                        )}
                        <FontAwesomeIcon icon={icon[answer]} />
                        <div style={{ marginTop: '19px' }}>
                          {item.slug !== 'browser' ? i18n.t(`deviceSecurity.deviceQuestion.${answer}`)
                            : i18n.t(`deviceSecurity.deviceQuestion.browsers.${answer}`)}
                        </div>
                      </IconWrapper>
                      <input
                        data-slug={item.slug !== 'browser' ? i18n.t(`deviceSecurity.deviceQuestion.${answer}`)
                          : i18n.t(`deviceSecurity.deviceQuestion.browsers.${answer}`)}
                        checked={answers[item.slug] === answer}
                        type="checkbox"
                        className={item.slug}
                        id={`${item.slug}-${answer}`}
                        name={answer}
                        disabled={action === 'edit' && ['deviceType', 'operatingSystem'].indexOf(item.slug) > -1}
                        onClick={() => handleClick(item.slug, answer, index)}
                      />
                    </label>
                  ))}
              </div>
            </QuestionWrapper>
          ))}
        {section === 0 && (
        <ConfirmButton disabled={disallowToProceedToStep2(answers)} onClick={handleProceedToSecurity} data-slug="deviceSecurity.deviceQuestion.proccedToSecurity">
          {i18n.t('deviceSecurity.deviceQuestion.confirm')}
          <FontAwesomeIcon icon={faArrowCircleRight} />
        </ConfirmButton>
        )}
        {section === 1
        && questions[answers.deviceType || 'computer']
          .filter((item:Question) => (answers.operatingSystem !== 'macOS'
          || (answers.operatingSystem === 'macOS' && item.slug !== 'antivirus')))
          .filter((item:Question, index:number) => ((answers.deviceType === 'computer' && index > 3)
            || (answers.deviceType !== 'computer' && index > 3)))
          .filter((item:Question) => (item.slug !== 'encryption' || answers.operatingSystem !== 'iOS'))
          .filter((item:Question, index:number) => {
            if (answers.deviceType === 'computer') {
              if (user?.deviceSecurity?.measures?.computer) {
                if (Object.keys(user?.deviceSecurity?.measures?.computer).filter((key:string) => user?.deviceSecurity?.measures?.computer[key]).indexOf(item.slug) === -1) {
                  return true;
                }
                return false;
              }
            } else if (user?.deviceSecurity?.measures?.mobile) {
              if (Object.keys(user?.deviceSecurity?.measures?.mobile).filter((key:string) => user?.deviceSecurity?.measures?.mobile[key]).indexOf(item.slug) === -1) {
                return true;
              }
              return false;
            }
            return true;
          })
          .filter((item:Question, index:number) => index <= question)
          .map((item:Question, index:number, arr:any[]) => (
            <>
              {index === question && (
              <>
                <SecurityQuestion>
                  <h2>
                    {i18n.t('deviceSecurity.deviceQuestion.question')}
                    {' '}
                    {index + 1}
                  </h2>
                  <p>{i18n.t(`deviceSecurity.deviceQuestion.${item.titleSlug}`)}</p>
                  <div style={{ display: 'inline-block', width: '100%' }}>
                    <label
                      style={{ verticalAlign: 'middle' }}
                      htmlFor={`${item.slug}-yes`}
                    >
                      <IconWrapper
                        className={answers[item.slug] === undefined ? ''
                          : answers[item.slug] === true ? 'green' : 'notSelected'}
                      >
                        <FontAwesomeIcon icon={icon.yes} />
                        <div style={{ marginTop: '19px' }}>
                          {i18n.t('deviceSecurity.deviceQuestion.yes')}
                        </div>
                      </IconWrapper>
                      <input
                        data-slug={`deviceSecurity.deviceQuestion${item.slug}.yes`}
                        checked={answers[item.slug] === true}
                        type="checkbox"
                        className={item.slug}
                        id={`${item.slug}-${'yes'}`}
                        name="yes"
                        onClick={() => handleClick(item.slug, 'yes', index)}
                      />
                    </label>
                    <label
                      style={{ verticalAlign: 'middle' }}
                      htmlFor={`${item.slug}-no`}
                    >
                      <IconWrapper
                        className={answers[item.slug] === undefined ? ''
                          : answers[item.slug] === false ? 'red' : 'notSelected'}
                      >
                        <FontAwesomeIcon icon={icon.no} />
                        <div style={{ marginTop: '19px' }}>
                          {i18n.t('deviceSecurity.deviceQuestion.no')}
                        </div>
                      </IconWrapper>
                      <input
                        data-slug={`deviceSecurity.deviceQuestion${item.slug}.no`}
                        checked={answers[item.slug] === false}
                        type="checkbox"
                        className={item.slug}
                        id={`${item.slug}-${'no'}`}
                        name="no"
                        onClick={() => handleClick(item.slug, 'no', index)}
                      />
                    </label>
                    {
                      answers.operatingSystem !== 'other' && (
                        <label
                          style={{ verticalAlign: 'middle' }}
                          data-slug={`deviceSecurity.deviceQuestion${item.slug}.dontKnow`}
                          onClick={() => {
                            setActiveIssue(item.slug);
                            setShowPopUp(true);
                          }}
                        >
                          <IconWrapper className={answers[item.slug] === undefined ? '' : 'notSelected'}>
                            <FontAwesomeIcon icon={icon.other} />
                            <div style={{ marginTop: '19px' }}>
                              { i18n.t('deviceSecurity.deviceQuestion.dontKnow')}
                            </div>
                          </IconWrapper>
                        </label>
                      )

              }
                  </div>
                  {answers[item.slug] && item.congrats && (
                  <GoodAnswerWrapper>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <div>
                      <h3>
                        {i18n.t(`deviceSecurity.deviceQuestion.${item.congrats.title}`)}
                      </h3>
                      <p>
                        {i18n.t(`deviceSecurity.deviceQuestion.${item.congrats.sub}`)}
                      </p>
                    </div>
                  </GoodAnswerWrapper>
                  )}
                </SecurityQuestion>
                <ConfirmButton
                  data-slug={`deviceSecurity.deviceQuestion${item.slug}.confirm`}
                  disabled={answers[item.slug] === undefined}
                  onClick={() => handleNextQuestion()}
                >
                  {i18n.t('deviceSecurity.deviceQuestion.nextQuestion')}
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </ConfirmButton>
              </>
              )}
            </>
          ))}
        {
        section === 2 && (
          <DeviceSummary>
            {Object.keys(answers)
              .filter((key:string) => (answers.operatingSystem !== 'macOS' || key !== 'antivirus'))
              .filter((key:string) => (key !== 'autoUpdateBrowser'
              || (answers.browser !== 'safari' && answers.operatingSystem === 'macOS')
              || (answers.browser !== 'IE' && answers.operatingSystem === 'windows')))
              .filter((key:string) => (answers[key] === false)).length > 0 && (
              <h2>
                <FontAwesomeIcon icon={faHammer} style={{ marginRight: '8px' }} />
                {i18n.t('deviceSecurity.deviceQuestion.issuesToFix')}
              </h2>

            )}
            {Object.keys(answers)
              .filter((key:string) => (answers.operatingSystem !== 'macOS' || key !== 'antivirus'))
              .filter((key:string) => (key !== 'autoUpdateBrowser'
              || (answers.browser !== 'safari' && answers.operatingSystem === 'macOS')
              || (answers.browser !== 'IE' && answers.operatingSystem === 'windows')))
              .filter((key:string) => (answers[key] === false))
              .sort((a, b) => i18n.t(`deviceSecurity.deviceQuestion.${a}Installed`)
                .localeCompare(i18n.t(`deviceSecurity.deviceQuestion.${b}Installed`)))
              .map((key:string) => (
                <BoxWithIssues>
                  <div style={{ display: 'flex' }}>
                    <div style={{
                      width: '100%',
                      maxWidth: '27px',
                      height: '27px',
                      backgroundColor: '#ffffff',
                      borderRadius: '27px',
                      textAlign: 'center',
                      marginRight: '22px',
                      padding: '3px 0',
                    }}
                    >
                      <FontAwesomeIcon icon={issuesIcons[key]} style={{ color: 'rgb(255, 139, 139)' }} />
                    </div>
                    <div style={{ maxWidth: '500px' }}>
                      <h2>{i18n.t(`deviceSecurity.deviceQuestion.${key}NotInstalled`)}</h2>
                      <p>{i18n.t(`deviceSecurity.deviceQuestion.${key}NotInstalledSub`)}</p>
                    </div>
                  </div>
                  <button
                    data-slug={`deviceSecurity.deviceQuestion.${key}.fixThisIssue`}
                    type="button"
                    onClick={() => {
                      if (answers.operatingSystem !== 'other') {
                        if (answers.ownership === 'personal') {
                          setActiveIssue(key);
                          setSection(3);
                        } else {
                          setActiveIssue(key);
                          setNeedCompanyPersmission(true);
                        }
                      } else {
                        setActiveIssue(key);
                        setQuestion(getEditingQuestion(key, answers.deviceType));
                        setSection(1);
                      }
                    }}
                  >
                    {i18n.t('deviceSecurity.deviceQuestion.fixThisIssue')}
                    <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: '12px' }} />
                  </button>
                </BoxWithIssues>
              ))}
            {Object.keys(answers)
              .filter((key:string) => (answers.operatingSystem !== 'macOS' || key !== 'antivirus'))
              .filter((key:string) => (key !== 'autoUpdateBrowser'
              || (answers.browser !== 'safari' && answers.operatingSystem === 'macOS')
              || (answers.browser !== 'IE' && answers.operatingSystem === 'windows')))
              .filter((key:string) => (answers[key] === true || answers[key] === 'byAdmin')).length > 0 && (
              <h2 style={{ marginTop: '38px' }}>

                <FontAwesomeIcon icon={faShieldAlt} style={{ marginRight: '8px' }} />
                {i18n.t('deviceSecurity.deviceQuestion.securityMearsures')}
              </h2>

            )}
            {Object.keys(answers)
              .filter((key:string) => ((answers.operatingSystem !== 'macOS' || key !== 'antivirus')
              && (answers.operatingSystem !== 'iOS' || key !== 'encryption')))
              .filter((key:string) => (key !== 'autoUpdateBrowser'
              || (answers.browser !== 'safari' && answers.operatingSystem === 'macOS')
              || (answers.browser !== 'IE' && answers.operatingSystem === 'windows')))
              .filter((key:string) => (answers[key] === true || answers[key] === 'byAdmin'))
              .sort((a, b) => i18n.t(`deviceSecurity.deviceQuestion.${a}Installed`)
                .localeCompare(i18n.t(`deviceSecurity.deviceQuestion.${b}Installed`)))
              .map((key:string) => (
                <BoxWithoutIssues key={key}>
                  <div style={{ display: 'flex' }}>
                    <div style={{
                      width: '100%', maxWidth: '27px', height: '27px', backgroundColor: 'rgb(124, 198, 73)', borderRadius: '27px', textAlign: 'center', marginRight: '22px', padding: '3px 0',
                    }}
                    >
                      {answers[key] === true && (
                      <FontAwesomeIcon icon={faCheck} style={{ color: '#ffffff' }} />
                      )}
                      {answers[key] === 'byAdmin' && (
                      <img src={CheckUser} style={{ backgroundColor: 'white', marginTop: '-3px', width: '27px' }} />
                      )}

                    </div>
                    <div style={{ maxWidth: '500px' }}>
                      <h2>{i18n.t(`deviceSecurity.deviceQuestion.${key}Installed`)}</h2>
                      <p>{i18n.t(`deviceSecurity.deviceQuestion.${key}InstalledSub`)}</p>
                    </div>
                  </div>
                  {answers[key] !== 'byAdmin' && (
                  <button
                    data-slug={`deviceSecurity.deviceQuestion.${key}.edit`}
                    type="button"
                    onClick={() => {
                      if (answers.operatingSystem !== 'other') {
                        if (answers.ownership === 'personal') {
                          setActiveIssue(key);
                          setSection(3);
                        } else {
                          setActiveIssue(key);
                          setNeedCompanyPersmission(true);
                        }
                      } else {
                        setActiveIssue(key);
                        setQuestion(getEditingQuestion(key, answers.deviceType));
                        setSection(1);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPen} style={{ marginRight: '11px' }} />
                    {i18n.t('deviceSecurity.deviceQuestion.edit')}
                  </button>
                  )}
                  {answers[key] === 'byAdmin' && (
                    <div style={{ fontWeight: 300, marginTop: '20px', color: 'rgb(70,70,70)' }}>
                      {i18n.t('deviceSecurity.deviceQuestion.adminConfirmed')}
                      <PopperOnHover text={i18n.t('deviceSecurity.deviceQuestion.adminConfirmedTooltip')}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            fontSize: '12px', marginBottom: '3px', marginLeft: ' 5px', cursor: 'pointer',
                          }}
                        />
                      </PopperOnHover>
                    </div>
                  )}
                </BoxWithoutIssues>
              ))}

            <h2 style={{ marginTop: '38px' }}>
              <FontAwesomeIcon icon={faPhoneLaptop} style={{ marginRight: '8px' }} />
              {i18n.t('deviceSecurity.deviceQuestion.deviceDetails')}
            </h2>
            <BoxWithoutIssues>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{
                  color: '#A3A3A3', fontSize: '12px', paddingRight: '10px', flexGrow: 1,
                }}
                >
                  {i18n.t('deviceSecurity.deviceQuestion.deviceType')}
                  <div style={{ fontSize: '20px', fontWeight: 900, color: '#448BFF' }}>{i18n.t(`deviceSecurity.deviceQuestion.${answers.deviceType}`)}</div>
                </div>
                <div style={{
                  color: '#A3A3A3', fontSize: '12px', paddingRight: '10px', flexGrow: 1,
                }}
                >
                  {i18n.t('deviceSecurity.deviceQuestion.operatingSystem')}
                  <div style={{ fontSize: '20px', fontWeight: 900, color: '#448BFF' }}>{i18n.t(`deviceSecurity.deviceQuestion.${answers.operatingSystem}`)}</div>
                </div>
                {answers.browser && (
                <div style={{
                  color: '#A3A3A3', fontSize: '12px', paddingRight: '10px', flexGrow: 1,
                }}
                >
                  {i18n.t('deviceSecurity.deviceQuestion.browser')}
                  <div style={{ fontSize: '20px', fontWeight: 900, color: '#448BFF' }}>{i18n.t(`deviceSecurity.deviceQuestion.browsers.${answers.browser}`)}</div>
                </div>
                )}
                <div style={{
                  color: '#A3A3A3', fontSize: '12px', paddingRight: '10px', flexGrow: 4,
                }}
                >
                  {i18n.t('deviceSecurity.deviceQuestion.ownership')}
                  <div style={{ fontSize: '20px', fontWeight: 900, color: '#448BFF' }}>{i18n.t(`deviceSecurity.deviceQuestion.${answers.ownership}`)}</div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  setSection(0);
                  setQuestion(3);
                }}
              >
                <FontAwesomeIcon icon={faPen} style={{ marginRight: '11px' }} />
                {i18n.t('deviceSecurity.deviceQuestion.edit')}
              </button>
            </BoxWithoutIssues>
            <div className="mobileButtonWrapper" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '56px' }}>
              <ButtonDelete
                data-slug="deviceSecurity.deviceQuestion.deleteDevice"
                onClick={() => {
                  if (id && id !== 'detected') {
                    dispatch(deleteDevice(answers, Number(id))).then(() => {
                      history(`/${i18n.getLanguage()}/device-security`);
                    });
                  } else {
                    history(`/${i18n.getLanguage()}/device-security`);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '14px' }} />
                {i18n.t('deviceSecurity.deviceQuestion.deleteDevice')}
              </ButtonDelete>
              <div style={{ width: '100%', maxWidth: '550px', textAlign: 'right' }}>
                {(!id || id === 'detected') && (
                <ButtonLight
                  data-slug="deviceSecurity.deviceQuestion.addAnotherDevice"
                  onClick={() => {
                    dispatch(addDevice(answers)).then(() => {
                      setSection(0);
                      setQuestion(0);
                      setAnswers({});
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: '20px' }} />
                  {i18n.t('deviceSecurity.deviceQuestion.addAnotherDevice')}
                </ButtonLight>
                )}
                <ButtonDark
                  data-slug="deviceSecurity.deviceQuestion.finish"
                  onClick={() => {
                    if (id && id !== 'detected') {
                      dispatch(updateDevice(answers, Number(id))).then(() => {
                        history(`/${i18n.getLanguage()}/device-security`);
                      });
                    } else {
                      dispatch(addDevice(answers)).then(() => {
                        history(`/${i18n.getLanguage()}/device-security`);
                      });
                    }
                  }}
                >
                  {i18n.t('finish')}
                </ButtonDark>
              </div>
            </div>
          </DeviceSummary>
        )
      }
        {
        (section === 3 && activeIssue) && (
        <IssueDetailsAddDevice
          activeIssue={activeIssue}
          answers={answers}
          setAnswers={setAnswers}
          setSection={setSection}
        />
        )
      }
      </AddDeviceWrapper>
    </>
  );
};

export default AddDevice;

const GoodAnswerWrapper = styled.button`
width: 100%;
background-color:#F9F9F9;
border-radius: 4.5px;
padding: 28px 23px;
color:#80BC41;
display:flex;
text-align:left;
border: none;
margin-top: 20px;
margin-bottom: 20px;

svg {
  margin-right: 20px;
  font-size: 30px;
}

 h3 {
   font-size: 26px;
   font-weight: 800;
   margin:0;
   line-height: 20px;
 }
 p {
   color: #5F5F5F;
   font-size: 19.5px;
   font-weight: 500;
   margin-bottom: 0;
 }
`;

const ButtonDelete = styled.button`
cursor:pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(49, 49, 49);
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 11px 10px;
  max-width: 160px;
  width: 100%;
`;

const ButtonLight = styled.button`
cursor:pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(53, 128, 249);
  font-size: 20px;
  font-weight: 500;
  border: 2px solid rgb(53, 128, 249);
  border-radius: 3.25px;
  padding: 11px 10px;
  max-width: 234px;
  width: 100%;
`;

const ButtonDark = styled.button`
  cursor:pointer;
  background-color: rgb(53, 128, 249);
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 500;
  border: 2px solid rgb(53, 128, 249);
  border-radius: 4.83px;
  padding: 11px 10px;
  margin-left: 24px;
  max-width: 189px;
  width: 100%;
`;

const BoxWithIssues = styled.div`
  max-width: 812px;
  width: 100%;
  border-radius: 5.2px;
  background-color: #FF8B8B;
  box-shadow: rgba(255, 148, 147, 0.19) 0 1px 14px 6px;
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
  margin-bottom: 20px;

  & h2{
    font-size: 23px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin: 0;
  }

  & p {
    font-size: 14px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    margin: 5px 0;
  }

  & button {
    background-color: rgb(255, 139, 139);
    color: rgb(255, 255, 255);
    font-size: 17px;
    font-weight: 600;
    border: none;
  }

`;

const BoxWithoutIssues = styled.div`
  max-width: 812px;
  width: 100%;
  border-radius: 5.2px;
  background-color: #ffffff;
  box-shadow: rgba(34, 33, 33, 0.04) 0 1px 14px 6px;
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
  margin-bottom: 20px;

  & h2{
    font-size: 23px;
    font-weight: 600;
    color: rgb(124, 198, 73);
    margin: 0;
  }

  & p {
    font-size: 14px;
    font-weight: 400;
    color: rgb(70, 70, 70);
    margin: 5px 0;
  }

  & button {
    background-color: rgb(255, 255, 255);
    color: rgb(60, 60, 60);
    font-size: 17px;
    font-weight: 600;
    border: none;
  }

`;

const DeviceSummary = styled.div`
 max-width: 800px;
& h2{
  font-size: 16px;
  color: #3E3E3E;
  font-weight: 900;
}
@media screen and (max-width: 500px) {
  .mobileButtonWrapper {
    flex-wrap: wrap;
    overflow:hidden;
  }
 }
`;


const SecurityQuestion = styled.div`
display: inline-block;
width: 100%;
margin-bottom: 20px;
border-top: 1px solid #979797;

 h2{
  font-size: 19px;
  color: #737373;
  font-weight: 600;
 }

 p {
   font-size: 24px;
   font-weight: 900;
   color: #2B2B2B;
   margin-bottom:32px;
 }

 input {
   display:none;
 }
 .green {
  color:white;
  background-color: #80BC41;
 }
 .red {
   background-color: #FF9898;
   color: white;
 }
`;

const ConfirmButton = styled.button`
cursor:pointer;
display:block;
width: 100%;
max-width: 187px;
background-color: #3580F9;
font-size: 21px;
color: white;
font-size: 600;
text-align:center;
padding: 14px 4px;
border: none;
border-radius: 5px;
margin-left:auto;

&:disabled {
  opacity: 0.48;
}

& svg {
  margin-left: 20px;
  vertical-align: middle;
}
`;

const IconWrapper = styled.div`
position: relative;
cursor:pointer;
width: 100%;
max-width: 125px;
display: inline-flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
margin-right: 20px;
margin-bottom: 10px;
height: 125px;
font-size: 17px;
color: #5C5C5C;
background-color:#F6F6F6;
border-radius:12px;
text-align:center;
padding: 19px 5px;
font-weight: 600;

& svg {
  font-size: 38px;
  margin-top: 6px;
}

& .thunder {
  font-size: 17px;
  margin-top: 0;
}

&.selected {
  color: white;
  background-color:#3580F9;
}

&.notSelected {
  opacity: 0.48;
}
 
`;

const QuestionWrapper = styled.div`
display: inline-block;
width: 100%;
margin-bottom: 20px;

&:disabled {
  opacity: 0.5;
}

label {
  vertical-align: bottom;
}

& h2 {
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 20px;
  color: #424242;
  display:inline-block;
}
input {
 display:none
}
`;

const AddDeviceWrapper = styled.div`
width: 100%;
max-width: 920px;
margin: 0 auto;
padding:0 10px;

& h1 {
  font-size: 39px;
  font-weight: 800;
}
& > p {
  font-weight: 400;
  color: #6f6f6f;
  font-size: 19px;
}
`;
