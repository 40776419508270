import { UAParser } from 'ua-parser-js';

export type PrimaryBrowser = 'chrome'|'edge'|'firefox'|'safari'|'IE'|'other';
export type ComputerOS = 'windows'|'macOS'|'other';
export type MobileOS = 'android'|'iOS'|'other';
export type OwnerShip = 'personal' | 'company';

export type Computer = {
  name?: string,
  deviceType?: 'computer',
  ownership?: OwnerShip,
  operatingSystem?: ComputerOS,
  browser?: PrimaryBrowser,
  antivirus?: boolean,
  firewall?: boolean,
  encryption?: boolean,
  autoUpdateOS?: boolean,
  autoUpdateBrowser?: boolean,

};

export type Mobile = {
  name?: string,
  deviceType?: 'mobile'|'tablet',
  ownership?: OwnerShip,
  operatingSystem?: MobileOS,
  browser?: PrimaryBrowser,
  screenlock?: boolean,
  encryption?: boolean,
  latestOS?: boolean,
  latestBrowser?: boolean,
}

export type Device = Mobile | Computer;

export type DeviceSurveyStatus = 'noSurveySent'|'noDevice'|'unsecured'|'secured'|'notCompleted';

export const getDeviceSurveyStatus = (survey:(Mobile|Computer)):DeviceSurveyStatus => {
  if (survey.deviceType && (survey.deviceType === 'tablet' || survey.deviceType === 'mobile')) {
    let secured:boolean|undefined = true;
    Object.keys(survey).forEach((question:string) => {
      if (!survey[question]) secured = false;
    });
    if (secured) {
      if (Object.keys(survey).length < 7) secured = undefined;
    }
    if (secured) return 'secured';
    if (secured === false) return 'unsecured';
    return 'notCompleted';
  }
  if (survey.deviceType && survey.deviceType === 'computer') {
    let secured:boolean|undefined = true;
    Object.keys(survey).forEach((question:string) => {
      if (!survey[question]) secured = false;
    });
    if (secured) {
      if (Object.keys(survey).length < 9) secured = undefined;
    }
    if (secured) return 'secured';
    if (secured === false) return 'unsecured';
    return 'notCompleted';
  }
  return 'notCompleted';
};


export type Question = {titleSlug: string, answers:string[], slug: string, congrats?:{title:string, sub:string}};
export const questions:{
  computer: Question[],
  tablet: Question[],
  mobile: Question[],
} = {
  computer: [
    {
      titleSlug: 'selectDeviceType',
      slug: 'deviceType',
      answers: [
        'mobile',
        'computer',
        'tablet',
      ],
    },
    {
      titleSlug: 'selectComputerOS',
      slug: 'operatingSystem',
      answers: [
        'windows',
        'macOS',
        'other',
      ],
    },
    {
      titleSlug: 'selectPrimaryBrowser',
      slug: 'browser',
      answers: [
        'chrome',
        'edge',
        'firefox',
        'safari',
        'IE',
        'other',
      ],
    },
    {
      titleSlug: 'selectOwnership',
      slug: 'ownership',
      answers: [
        'personal',
        'company',
      ],
    },
    {
      titleSlug: 'antivirusQuestion',
      slug: 'antivirus',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'antivirusCongratsTitle',
        sub: 'antivirusCongratsSub',
      },
    },
    {
      titleSlug: 'firewallQuestion',
      slug: 'firewall',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'firewallCongratsTitle',
        sub: 'firewallCongratsSub',
      },
    },
    {
      titleSlug: 'encryptionQuestion',
      slug: 'encryption',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'encryptionCongratsTitle',
        sub: 'encryptionCongratsSub',
      },
    },
    {
      titleSlug: 'autoUpdateOSQuestion',
      slug: 'autoUpdateOS',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'autoUpdateOSCongratsTitle',
        sub: 'autoUpdateOSCongratsSub',
      },
    },
    {
      titleSlug: 'autoUpdateBrowserQuestion',
      slug: 'autoUpdateBrowser',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'autoUpdateBrowserCongratsTitle',
        sub: 'autoUpdateBrowserCongratsSub',
      },
    },
  ],
  mobile: [
    {
      titleSlug: 'selectDeviceType',
      slug: 'deviceType',
      answers: [
        'mobile',
        'computer',
        'tablet',
      ],
    },
    {
      titleSlug: 'selectMobileOS',
      slug: 'operatingSystem',
      answers: [
        'iOS',
        'android',
        'other',
      ],
    },
    {
      titleSlug: 'selectPrimaryBrowser',
      slug: 'browser',
      answers: [
        'chrome',
        'edge',
        'firefox',
        'safari',
        'other',
      ],
    },
    {
      titleSlug: 'selectOwnership',
      slug: 'ownership',
      answers: [
        'personal',
        'company',
      ],
    },
    {
      titleSlug: 'screenlockQuestion',
      slug: 'screenlock',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'screenlockCongratsTitle',
        sub: 'screenlockCongratsSub',
      },
    },
    {
      titleSlug: 'encryptionQuestion',
      slug: 'encryption',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'encryptionCongratsTitle',
        sub: 'encryptionCongratsSub',
      },
    },
    {
      titleSlug: 'latestOSQuestion',
      slug: 'latestOS',
      answers: [
        'yes',
        'no',
      ],
    },
    {
      titleSlug: 'latestBrowserQuestion',
      slug: 'latestBrowser',
      answers: [
        'yes',
        'no',
      ],
    },
  ],
  tablet: [
    {
      titleSlug: 'selectDeviceType',
      slug: 'deviceType',
      answers: [
        'mobile',
        'computer',
        'tablet',
      ],
    },
    {
      titleSlug: 'selectTabletOS',
      slug: 'operatingSystem',
      answers: [
        'iOS',
        'android',
        'other',
      ],
    },
    {
      titleSlug: 'selectPrimaryBrowser',
      slug: 'browser',
      answers: [
        'chrome',
        'edge',
        'firefox',
        'safari',
        'other',
      ],
    },
    {
      titleSlug: 'selectOwnership',
      slug: 'ownership',
      answers: [
        'personal',
        'company',
      ],
    },
    {
      titleSlug: 'screenlockQuestion',
      slug: 'screenlock',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'screenlockCongratsTitle',
        sub: 'screenlockCongratsSub',
      },
    },
    {
      titleSlug: 'encryptionQuestion',
      slug: 'encryption',
      answers: [
        'yes',
        'no',
      ],
      congrats: {
        title: 'encryptionCongratsTitle',
        sub: 'encryptionCongratsSub',
      },
    },
    {
      titleSlug: 'latestOSQuestion',
      slug: 'latestOS',
      answers: [
        'yes',
        'no',
      ],
    },
    {
      titleSlug: 'latestBrowserQuestion',
      slug: 'latestBrowser',
      answers: [
        'yes',
        'no',
      ],
    },
  ],
};

export const getDetectedDevice = (ua:UAParser) => {
  const dev = ua.getResult();
  if (!dev) return {};
  const OS:(ComputerOS|MobileOS)[] = ['windows', 'macOS', 'android', 'iOS'];
  const lowCaseOS = OS.map((item:(ComputerOS|MobileOS)) => item.toLowerCase());
  const deviceType:any = ['computer', 'mobile', 'tablet'];
  const browser:PrimaryBrowser[] = ['chrome', 'firefox', 'IE', 'safari', 'edge'];
  const lowCaseBrowser = browser.map((item:PrimaryBrowser) => item.toLowerCase());
  const device:any = {};
  if (dev.os && dev.os.name && lowCaseOS.indexOf(dev.os.name.toLowerCase().replace(/\s/g, '')) > -1) {
    device.operatingSystem = OS[lowCaseOS.indexOf(dev.os.name.toLowerCase().replace(/\s/g, ''))];
  }
  if (dev.device) {
    if (dev.device.type && deviceType.indexOf(dev.device.type.toLowerCase()) > -1) {
      device.deviceType = deviceType[deviceType.indexOf(dev.device.type.toLowerCase())];
    } else if (!dev.device.type) {
      device.deviceType = 'computer';
    }
  }
  if (dev.browser && dev.browser.name
    && lowCaseBrowser.indexOf(dev.browser.name.toLowerCase()) > -1) {
    device.browser = browser[lowCaseBrowser.indexOf(dev.browser.name.toLowerCase())];
  }

  return device;
};

export const checkIfSimilarDeviceExists = (detected:Device, saved:any) => {
  if (!detected || !saved || saved.length === 0) return false;
  const found = saved.find((item:any) => item.device.operatingSystem === detected.operatingSystem);
  if (found) return true;
  return false;
};

const storageBaseUrl = 'https://storage.googleapis.com/bewica-assets';
const tutorialBaseUrl = `${storageBaseUrl}/tutorials`;
const videoBaseUrl = `${storageBaseUrl}/videos`;

export const questionMedia = {
  antivirus: {
    windows: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Antivirus%20tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Antivirus%20Anleitung%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Antivirus%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/Windows%20Antivirus.mp4`,
        es: `${videoBaseUrl}/es/Windows%20Antivirus.mp4`,
        de: `${videoBaseUrl}/de/Windows%20Antivirus.mp4`,
      },
    },
  },
  encryption: {
    windows: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Encryption%20tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Verschlüsselung%20Anleitung%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Encriptacion%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/Windows Encryption.mp4`,
        es: `${videoBaseUrl}/es/Windows Encryption.mp4`,
        de: `${videoBaseUrl}/de/Windows Encryption.mp4`,
      },
    },
    macOS: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Encryption%20tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Verschlüsselung%20Anleitung%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Encriptacion%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/MacOS Encryption.mp4`,
        de: `${videoBaseUrl}/de/MacOS Encryption.mp4`,
        es: `${videoBaseUrl}/es/MacOS Encryption.mp4`,
      },
    },
    android: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Encryption%20tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Verschlüsselung%20Anleitung%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Encriptacion%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/Android Encryption.mp4`,
        de: `${videoBaseUrl}/de/Android Encryption.mp4`,
        es: `${videoBaseUrl}/es/Android Encryption.mp4`,
      },
    },
    iOS: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Encryption%20tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Verschlüsselung%20Anleitung%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Encriptacion%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/iOS Encryption & Lock.mp4`,
        es: `${videoBaseUrl}/es/iOS Encryption & Lock.mp4`,
        de: `${videoBaseUrl}/de/iOS Encryption & Lock.mp4`,
      },
    },
  },
  autoUpdateBrowser: {
    windows: {
      chrome: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Browser%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20Browser%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Navegadores%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/Windows Chrome.mp4`,
          es: `${videoBaseUrl}/es/Windows Chrome.mp4`,
          de: `${videoBaseUrl}/de/Windows Chrome.mp4`,
        },
      },
      IE: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20OS%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20OS%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Sistemas%20operativos%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/Windows OS Updates.mp4`,
          de: `${videoBaseUrl}/de/Windows OS Updates.mp4`,
          es: `${videoBaseUrl}/es/Windows OS Updates.mp4`,
        },
      },
      firefox: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Browser%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20Browser%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Navegadores%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/Windows Firefox.mp4`,
          es: `${videoBaseUrl}/es/Windows Firefox.mp4`,
          de: `${videoBaseUrl}/de/Windows Firefox.mp4`,
        },
      },
      edge: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Browser%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20Browser%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Navegadores%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/Windows Edge.mp4`,
          es: `${videoBaseUrl}/es/Windows Edge.mp4`,
          de: `${videoBaseUrl}/de/Windows Edge.mp4`,
        },
      },
      other: {

      },
    },
    macOS: {
      chrome: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Browser%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20Browser%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Navegadores%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/MacOS Chrome.mp4`,
          de: `${videoBaseUrl}/de/MacOS Chrome.mp4`,
          es: `${videoBaseUrl}/es/MacOS Chrome.mp4`,
        },
      },
      firefox: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Browser%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20Browser%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Navegadores%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/MacOS Firefox.mp4`,
          de: `${videoBaseUrl}/de/MacOS Firefox.mp4`,
          es: `${videoBaseUrl}/es/MacOS Firefox.mp4`,
        },
      },
      safari: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Browser%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20Browser%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Navegadores%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/MacOS Safari.mp4`,
          es: `${videoBaseUrl}/es/MacOS Safari.mp4`,
          de: `${videoBaseUrl}/de/MacOS Safari.mp4`,
        },
      },
      edge: {
        tutorial: {
          en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Browser%20Updates%20Tutorial.pdf`,
          de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20Browser%20DE.pdf`,
          es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Navegadores%20actualizados%20tutorial.pdf`,
        },
        video: {
          en: `${videoBaseUrl}/en/Windows Edge.mp4`,
          es: `${videoBaseUrl}/es/Windows Edge.mp4`,
          de: `${videoBaseUrl}/de/Windows Edge.mp4`,
        },
      },
      other: {

      },
    },
  },
  autoUpdateOS: {
    windows: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20OS%20Updates%20Tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20OS%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Sistemas%20operativos%20actualizados%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/Windows OS Updates.mp4`,
        de: `${videoBaseUrl}/de/Windows OS Updates.mp4`,
        es: `${videoBaseUrl}/es/Windows OS Updates.mp4`,
      },
    },
    macOS: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20OS%20Updates%20Tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20OS%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Sistemas%20operativos%20actualizados%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/MacOS Updates.mp4`,
        de: `${videoBaseUrl}/de/MacOS Updates.mp4`,
        es: `${videoBaseUrl}/es/MacOS Updates.mp4`,
      },
    },
  },
  latestOS: {
    android: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20OS%20updates%20Tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20OS%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Sistemas%20operativos%20actualizados%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/Android OS Updates.mp4`,
        es: `${videoBaseUrl}/es/Android OS Updates.mp4`,
        de: `${videoBaseUrl}/de/Android OS Updates.mp4`,
      },
    },
    iOS: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/Bewica%20OS%20Updates%20Tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20OS%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Sistemas%20operativos%20actualizados%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/iOS OS Updates.mp4`,
        es: `${videoBaseUrl}/es/iOS OS Updates.mp4`,
        de: `${videoBaseUrl}/de/iOS OS Updates.mp4`,
      },
    },
  },
  latestBrowser: {
    android: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20OS%20updates%20Tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20OS%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Sistemas%20operativos%20actualizados%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/Android OS Updates.mp4`,
        es: `${videoBaseUrl}/es/Android OS Updates.mp4`,
        de: `${videoBaseUrl}/de/Android OS Updates.mp4`,
      },
    },
    iOS: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/Bewica%20OS%20Updates%20Tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Aktualisierung%20Anleitung%20OS%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Sistemas%20operativos%20actualizados%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/iOS OS Updates.mp4`,
        es: `${videoBaseUrl}/es/iOS OS Updates.mp4`,
        de: `${videoBaseUrl}/de/iOS OS Updates.mp4`,
      },
    },
  },
  firewall: {
    windows: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Firewall%20tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Firewall%20Anleitung%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Cortafuegos%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/Windows Firewall.mp4`,
        de: `${videoBaseUrl}/de/Windows Firewall.mp4`,
        es: `${videoBaseUrl}/es/Windows Firewall.mp4`,
      },
    },
    macOS: {
      tutorial: {
        en: `${tutorialBaseUrl}/en/01-2021/Bewica%20Firewall%20tutorial.pdf`,
        de: `${tutorialBaseUrl}/de/Bewica%20Firewall%20Anleitung%20DE.pdf`,
        es: `${tutorialBaseUrl}/es/01-2021/Bewica%20Cortafuegos%20tutorial.pdf`,
      },
      video: {
        en: `${videoBaseUrl}/en/MacOS Firewall.mp4`,
        es: `${videoBaseUrl}/es/MacOS Firewall.mp4`,
        de: `${videoBaseUrl}/de/MacOS Firewall.mp4`,
      },
    },
  },
  screenlock: {
    android: {
      video: {
        en: `${videoBaseUrl}/en/Android Screenlock.mp4`,
        de: `${videoBaseUrl}/de/Android Screenlock.mp4`,
        es: `${videoBaseUrl}/es/Android Screenlock.mp4`,
      },
    },
    iOS: {
      video: {
        en: `${videoBaseUrl}/en/iOS Encryption & Lock.mp4`,
        de: `${videoBaseUrl}/de/iOS Encryption & Lock.mp4`,
        es: `${videoBaseUrl}/es/iOS Encryption & Lock.mp4`,
      },
    },
  },
};
