import React from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faChevronRight, faFrown, faMeh, faSmile,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from '../../i18n';


const QuestionTitle = styled.h2`
font-size: 30px;
font-weight: 900;
color: #393939;
margin-bottom: 10px;
`;

const QuestionSubTitle = styled.h3`
font-size: 20px;
font-weight: 400;
color: #393939;
margin: 0;
line-height: 20px;
`;

const Footer = styled.div`

`;

const Button = styled.button`
width: 100%;
max-width: 245px;
height: 68px;
border-radius: 4px;
background-color: #bc1224;
color: white;
margin-right: 50px;
font-size: 30px;
font-weight: 700;
border:none;
cursor: pointer;
`;

const ElementFoundWrapper = styled.div`
color: ${(props) => (props.found > 0 ? '#2FBE3C' : '#D75353')};
font-size: 24px;
font-weight: 500;
display: inline-block;
`;

const ContentWrapper = styled.div`
padding: 40px 0;
margin: 0 auto;
`;

const Question = (props) => {
  const i18n = useTranslation();
  const {
    children, currentQuestion, stats, setSubmiting, switchQuestion, submiting, total, finishQuiz,
  } = props;

  let found = 0;
  if (stats && stats[currentQuestion] && stats[currentQuestion].found) {
    found = Object.keys(stats[currentQuestion].found).length;
  }

  let type;
  if (stats && stats[currentQuestion] && stats[currentQuestion].type) {
    type = stats[currentQuestion].type;
  }

  return (
    <>
      {!submiting && (
      <>
        <QuestionTitle>{i18n.t(`quiz.q${currentQuestion + 1}.title`)}</QuestionTitle>
        <QuestionSubTitle>{i18n.t(`quiz.q${currentQuestion + 1}.subTitle`)}</QuestionSubTitle>
      </>
      )}
      {(submiting && found === 0) && (
      <>
        {type === 'trueFalse' && (
          <>
            <QuestionTitle style={{ color: '#E36666' }}>
              {i18n.t('quiz.wrongChoice')}
              <FontAwesomeIcon icon={faFrown} style={{ marginLeft: '20px' }} />
            </QuestionTitle>
            <QuestionSubTitle dangerouslySetInnerHTML={{ __html: i18n.t(`quiz.q${currentQuestion + 1}.failedSubtitle`) }} />
          </>
        )}
        {type !== 'trueFalse' && (
          <QuestionTitle style={{ color: '#E36666' }}>
            {i18n.t('quiz.failedQuestion')}
            <FontAwesomeIcon icon={faFrown} style={{ marginLeft: '20px' }} />
          </QuestionTitle>
        )}
      </>
      )}
      {(submiting && found === total) && (
      <>
        <QuestionTitle style={{ color: '#4AB52C' }}>
          {i18n.t('quiz.niceJob')}
          <FontAwesomeIcon icon={faSmile} style={{ marginLeft: '20px' }} />
        </QuestionTitle>
      </>
      )}
      {(submiting && found > 0 && found < total) && (
      <>
        <QuestionTitle style={{ color: '#F7B500' }}>
          {i18n.t('quiz.missedX', { number: total - found })}
          <FontAwesomeIcon icon={faMeh} style={{ marginLeft: '20px' }} />
        </QuestionTitle>
        <QuestionSubTitle dangerouslySetInnerHTML={{ __html: i18n.t(`quiz.q${currentQuestion + 1}.failedSubtitle`) }} />
      </>
      )}
      <ContentWrapper>
        {children}
      </ContentWrapper>
      <Footer>
        {!submiting && (
        <Button onClick={() => setSubmiting(true)}>
          {i18n.t('submit')}
          &nbsp;
          &nbsp;
          <FontAwesomeIcon icon={faCheck} style={{ fontSize: '26px' }} />
        </Button>
        )}
        {(submiting && currentQuestion < 5) && (
        <Button onClick={switchQuestion} style={{ fontSize: '22px' }}>
          {i18n.t('quiz.nextQuestion')}
        </Button>
        )}
        {(submiting && currentQuestion === 5) && (
        <Button onClick={finishQuiz}>
          {i18n.t('finish')}
          {' '}
          &nbsp;
          <FontAwesomeIcon icon={faCheck} style={{ fontSize: '26px' }} />
        </Button>
        )}
        {type === 'email'
        && (
        <ElementFoundWrapper found={found}>
          {found}
          {' '}
          {found === 1 ? i18n.t('quiz.elementFound') : i18n.t('quiz.elementsFound')}
        </ElementFoundWrapper>
        )}
      </Footer>
    </>
  );
};

export default Question;
