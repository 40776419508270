import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ModalImage from 'react-modal-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; import {
  faSquare,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowCircleRight, faBug, faFireAlt, faKey, faSyncAlt, faFilePdf, faVideo, faArrowLeft,
  faCheckSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { i18n } from '../../i18n';
import { questionMedia } from '../helper';
import { updateDevice } from '../../redux/actions/devices.actions';

const issuesIcons = {
  screenlock: faBug,
  antivirus: faBug,
  firewall: faFireAlt,
  encryption: faKey,
  autoUpdateBrowser: faSyncAlt,
  autoUpdateOS: faSyncAlt,
  latestOS: faSyncAlt,
};

const IssueDetailsAddDevice:React.FC<{
  activeIssue:string, answers:any, setAnswers:any, setSection:any
}> = ({
  activeIssue, answers, setAnswers, setSection,
}) => {
  const [initState, setInitState] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    setInitState(answers[activeIssue]);
  }, []);

  return (
    <IssueDetail>
      <h1>
        <FontAwesomeIcon icon={issuesIcons[activeIssue]} style={{ marginRight: '17px', fontSize: '34px' }} />
        {i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.title`)}
      </h1>
      <p className="intro">
        {i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.subtitle`)}
      </p>
      { activeIssue === 'autoUpdateBrowser' && (
      <h3>{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.contentHeader`)}</h3>
      )}
      { activeIssue !== 'autoUpdateBrowser' && (
      <h3>{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.contentHeader`)}</h3>
      )}

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ minWidth: '220px' }}>
          {Array.isArray(i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.content`))
          && i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.content`)
          && activeIssue !== 'autoUpdateBrowser' && (
            <>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.content`)[0]}</p>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.operatingSystem}.content`)[1]}</p>
            </>
          )}
          {Array.isArray(i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.content`))
          && i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.content`)
          && activeIssue === 'autoUpdateBrowser' && (
            <>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.content`)[0]}</p>
              <p className="content">{i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.${answers.browser}.content`)[1]}</p>
            </>
          )}
          {activeIssue === 'autoUpdateBrowser' && (
          <button data-slug="deviceSecurity.deviceQuestion.viewTutorialPDF" className="outline" type="button" onClick={() => window.open(questionMedia[activeIssue][answers.operatingSystem][answers.browser].tutorial[i18n.lang], '_blank')}>
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ marginRight: '19.5px', fontSize: '24px', verticalAlign: 'bottom' }}
            />
            {i18n.t('deviceSecurity.deviceQuestion.viewTutorialPDF')}
          </button>
          )}
          {(activeIssue !== 'autoUpdateBrowser' && questionMedia[activeIssue][answers.operatingSystem].tutorial) && (
          <button
            data-slug="deviceSecurity.deviceQuestion.viewTutorialPDF"
            className="outline"
            type="button"
            onClick={() => window.open(questionMedia[activeIssue][answers.operatingSystem].tutorial[i18n.lang], '_blank')}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ marginRight: '19.5px', fontSize: '24px', verticalAlign: 'bottom' }}
            />
            {i18n.t('deviceSecurity.deviceQuestion.viewTutorialPDF')}
          </button>
          )}
        </div>
        {activeIssue === 'autoUpdateBrowser' && questionMedia[activeIssue][answers.operatingSystem]?.[answers.browser]?.gif?.[i18n.lang] && (
          <div className="imageContent">
            <ModalImage
              small={questionMedia[activeIssue][answers.operatingSystem][answers.browser].gif[i18n.lang]}
              large={questionMedia[activeIssue][answers.operatingSystem][answers.browser].gif[i18n.lang]}
              alt="gif"
              style={{
                width: '100%',
                borderRadius: '3px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0,
              }}
            />
          </div>
        )}
        {activeIssue === 'autoUpdateBrowser' && questionMedia[activeIssue][answers.operatingSystem]?.[answers.browser]?.video?.[i18n.lang]
        && (
          <div className="imageContent">
            <video
              controls
              autoPlay
              src={questionMedia[activeIssue][answers.operatingSystem][answers.browser].video[i18n.lang]}
              style={{
                width: '100%',
                height: '208px',
                borderRadius: '3px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0,
              }}
            />
          </div>
        )}
        {activeIssue !== 'autoUpdateBrowser' && (
        <div className="imageContent">
          {questionMedia[activeIssue][answers.operatingSystem].gif?.[i18n.lang]
        && (
          <ModalImage
            small={questionMedia[activeIssue][answers.operatingSystem].gif[i18n.lang]}
            large={questionMedia[activeIssue][answers.operatingSystem].gif[i18n.lang]}
            alt="gif"
            style={{
              width: '100%',
              borderRadius: '3px',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
            }}
          />
        )}
          {questionMedia[activeIssue][answers.operatingSystem].video?.[i18n.lang]
        && (
          <video
            controls
            autoPlay
            src={questionMedia[activeIssue][answers.operatingSystem].video[i18n.lang]}
            style={{
              width: '100%',
              height: '208px',
              borderRadius: '3px',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 0,
            }}
          />
          )}
        </div>
        )}
      </div>
      <div style={{
        marginTop: '38px',
        backgroundColor: !answers[activeIssue] ? 'rgb(245, 245, 245)' : '#E8FFED',
        borderRadius: '5.2px',
        display: 'inline-block',
        padding: '14px 27px 14px 10px',
      }}
      >
        <input
          data-slug={`deviceSecurity.deviceQuestion.${activeIssue}.iHaveInstalled`}
          style={{ display: 'none' }}
          type="checkbox"
          id={activeIssue}
          name={activeIssue}
          value="installed"
          onChange={() => {
            if (((answers.operatingSystem === 'windows' && answers.browser === 'IE')
            || (answers.operatingSystem === 'macOS' && answers.browser === 'safari')) && activeIssue === 'autoUpdateOS') {
              setAnswers({ ...answers, [activeIssue]: !answers[activeIssue], autoUpdateBrowser: !answers[activeIssue] });
            } else {
              setAnswers({ ...answers, [activeIssue]: !answers[activeIssue] });
            }
          }}
        />
        <label
          htmlFor={activeIssue}
          style={{
            fontSize: '23px',
            color: !answers[activeIssue] ? 'rgb(70, 70, 70)' : '#36CA69',
            fontWeight: 900,
            marginLeft: '19.5px',
          }}
        >
          {!answers[activeIssue] && (
          <FontAwesomeIcon icon={faSquare} style={{ marginRight: '20px' }} />
          )}
          {answers[activeIssue] && (
          <FontAwesomeIcon icon={faCheckSquare} style={{ marginRight: '20px' }} />
          )}
          {i18n.t(`deviceSecurity.deviceQuestion.${activeIssue}.iHaveInstalled`)}
        </label>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '61px',
      }}
      >
        <button
          data-slug={`deviceSecurity.deviceQuestion.${activeIssue}.goBack"`}
          className="back"
          type="button"
          onClick={() => {
            setAnswers({ ...answers, [activeIssue]: initState });
            setSection(2);
          }}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{
              marginRight: '20px',
              fontSize: '14px',
              color: 'rgb(80, 80, 80)',
            }}
          />
          {i18n.t('deviceSecurity.deviceQuestion.goBack')}
        </button>
        <button
          data-slug={`deviceSecurity.deviceQuestion.${activeIssue}.confirm"`}
          className="confirm"
          type="button"
          disabled={initState === answers[activeIssue]}
          onClick={() => {
            dispatch(updateDevice({ ...answers }, Number(id)));
            setSection(2);
          }}
        >
          {i18n.t('deviceSecurity.deviceQuestion.confirm')}
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            style={{
              marginLeft: '19.5px', fontSize: '24px', color: '#fff', verticalAlign: 'middle',
            }}
          />
        </button>
      </div>
    </IssueDetail>
  );
};

export default IssueDetailsAddDevice;

const IssueDetail = styled.div`
width:100%;
max-width: 812px;

& p {
  font-weight: 400;
}

& p.intro {
  width: 100%; 
  max-width: 812px;
  font-size: 19px;
  color: rgb(111, 111, 111);
  letter-spacing: 0.3px;
}

& p.content {
  font-size: 18px;
  color: rgb(83, 83, 83);
  max-width: 370px;
  width:100%;
  margin-top: 16px;
  margin-bottom: 0;
}

& h3 {
  font-size:18px;
  font-weight: 900;
  color: rgb(65, 65, 65);
  margin: 35px 0 10px;
}

& button.outline {
  border: 1px solid rgb(53, 128, 249);
  border-radius: 4.55px;
  padding: 10px 0;
  color: rgb(53, 128, 249);
  font-size: 19.5px;
  font-weight: 400;
  background-color: #fff;
  margin-top: 40px;
  text-align: center;
  width: 100%;
  max-width: 249px;
}

& button.back {
  border: none;
  padding: 10px 0;
  color: rgb(75, 75, 75);
  font-size: 21px;
  font-weight: 500;
  background-color: #fff;
  text-align: center;
  width: 100%;
  max-width: 114px;
}

& button.confirm {
  border: 1px solid rgb(53, 128, 249);
  border-radius: 4.8px;
  padding: 10px 0;
  color: rgb(255, 255, 255);
  font-size: 19.5px;
  font-weight: 500;
  background-color: rgb(53, 128, 249);
  text-align: center;
  width: 100%;
  max-width: 189px;
  margin-right: 40px;
}
& button.confirm:disabled {
  opacity: 0.4;
}

.imageContent {
  background-color: rgb(216, 216, 216);
  border-radius: 5.2px;
  width: 100%;
  height: 208px;
  max-width: 366.6px;
  text-align: center;
  position: relative;
}

`;
