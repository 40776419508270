import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.css';
import {
  faEnvelope, faChevronRight, faBug, faMask, faTrash, faBan, faFlag, faShieldAlt, faUserTie, faHandHoldingUsd, faUserFriends, faDollarSign, faShieldVirus, faFolderOpen, faPhone, faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { faHandshake } from '@fortawesome/pro-regular-svg-icons';
import phishingImage from '../../img/hook.png';
import phishingSignsEN from '../../img/phishing-signsEN.svg';
import phishingSignsES from '../../img/phishing-signsES.svg';
import phone from '../../img/phone.svg';
import amazonPaypal from '../../img/amazon-paypal.png';
import slide1 from '../../img/slide1.svg';
import slide2 from '../../img/slide2.svg';
import slide7 from '../../img/slide7.svg';
import slide7ES from '../../img/correos.svg';
import slide8 from '../../img/slide8.svg';
import slide13 from '../../img/slide13.svg';
import slide14 from '../../img/slide14.svg';
import { useTranslation } from '../../i18n';

import './slides.css';

const Slide3 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.whatIsPhishing')}</h1>
      <div className="wraps">
        <div className="text" style={{ width: '65%' }}>
          <p style={{ fontWeight: 'bold', fontSize: '22px' }}>
            {i18n.t('tutorial.phishingP1')}
          </p>
          <p>
            {i18n.t('tutorial.phishingP2')}
          </p>
          <p>
            {i18n.t('tutorial.phishingP3')}
          </p>
        </div>
        <img src={phishingImage} className="phishingImage" alt="phishing" />
      </div>
    </div>
  );
};

const Slide1 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.learnAboutPhishing')}</h1>
      <div className="wraps">
        <div className="text" style={{ width: '65%', display: 'flex', flexDirection: 'column' }}>
          <p style={{ fontWeight: 'bold', fontSize: '22px' }}>
            {i18n.t('tutorial.learnAboutPhishingP1')}
          </p>
          <div style={{ display: 'flex' }}>
            <div>
              <div style={{
                background: '#454545', width: '10px', height: '10px', borderRadius: '10px', marginLeft: '20px', marginRight: '10px', marginTop: '29px',
              }}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.learnAboutPhishingP2') }} />
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <div style={{
                background: '#454545', width: '10px', height: '10px', borderRadius: '10px', marginLeft: '20px', marginRight: '10px', marginTop: '30px',
              }}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.learnAboutPhishingP3') }} />
          </div>
          <div style={{ display: 'flex' }}>
            <div>
              <div style={{
                background: '#454545', width: '10px', height: '10px', borderRadius: '10px', marginLeft: '20px', marginRight: '10px', marginTop: '28px',
              }}
              />
            </div>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.learnAboutPhishingP4') }} />
          </div>
          <div style={{ marginTop: '30px', fontSize: '14px' }}>
            {i18n.t('tutorial.learnAboutPhishingSource')}
          </div>
        </div>
        <img src={slide1} className="phishingImage" alt="phishing" />
      </div>
    </div>
  );
};

const Slide2 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.dontForget')}</h1>
      <div className="wraps">
        <div className="text" style={{ width: '65%', display: 'flex', flexDirection: 'column' }}>
          <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.dontForgetP1') }} />
          <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.dontForgetP2') }} />
          <div style={{
            marginTop: 'auto', fontSize: '20px', display: 'flex', color: '#454545',
          }}
          >
            <FontAwesomeIcon icon={faShieldAlt} style={{ fontSize: '60px', color: '#BC1224', marginRight: '20px' }} />
            <div>{i18n.t('tutorial.dontForgetP3')}</div>
          </div>
        </div>
        <img src={slide2} className="phishingImage" alt="phishing" />
      </div>

    </div>
  );
};


const Slide4 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.howItWorks')}</h1>
      <p>{i18n.t('tutorial.howItWorksP1')}</p>
      <h3>{i18n.t('tutorial.howItWorksExample')}</h3>
      <div
        className="flexRowCenter"
        style={{
          marginTop: '40px', marginBottom: '20px', justifyContent: 'space-around', alignItems: 'unset',
        }}
      >
        <div className="step" style={{ boxSizing: 'content-box', height: 'auto' }}>
          <FontAwesomeIcon className="iconExample" icon={faMask} color="#BC1224" size="2x" />
          <h3 style={{ maxWidth: '200px' }}>{i18n.t('tutorial.exampleStep1')}</h3>
        </div>
        <FontAwesomeIcon className="iconStep2" icon={faChevronRight} color="#BC1224" size="2x" style={{ margin: 'auto 0' }} />
        <div className="step" style={{ boxSizing: 'content-box', height: 'auto' }}>
          <FontAwesomeIcon className="iconExample" icon={faEnvelope} color="#BC1224" size="2x" />
          <h3 style={{ maxWidth: '200px' }}>{i18n.t('tutorial.exampleStep2')}</h3>
        </div>
        <FontAwesomeIcon className="iconStep2" icon={faChevronRight} color="#BC1224" size="2x" style={{ margin: 'auto 0' }} />
        <div className="step" style={{ boxSizing: 'content-box', height: 'auto' }}>
          <FontAwesomeIcon className="iconExample" icon={faBug} color="#BC1224" size="2x" />
          <h3 style={{ maxWidth: '200px' }}>{i18n.t('tutorial.exampleStep3')}</h3>
        </div>
      </div>
    </div>
  );
};

const Slide5 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.whoCouldBeTheSender.title')}</h1>
      <p>{i18n.t('tutorial.whoCouldBeTheSender.paragraph')}</p>
      <div
        className="flexRowCenter"
        style={{
          alignItems: 'end', marginTop: '40px', marginBottom: '40px', justifyContent: 'space-around',
        }}
      >
        <div className="slide-5-container">
          <div className="slide-5-column">
            <h3>
              {i18n.t('tutorial.whoCouldBeTheSender.case1')}
              {' '}
            </h3>
            <div className="circle" style={{ boxSizing: 'content-box', width: '220px', marginTop: '20px' }}>
              <img src={amazonPaypal} alt="amazon paypal" style={{ width: '220px', marginTop: '20px' }} />
            </div>
            <div style={{ width: '100%', height: '60px' }} />
          </div>
          <div className="slide-5-column">
            <h3 style={{ marginTop: '10px' }}>{i18n.t('tutorial.whoCouldBeTheSender.case2')}</h3>
            <div className="circle" style={{ boxSizing: 'content-box', marginTop: '45px', width: '220px' }}>
              <h3 style={{ maxWidth: '95%', marginTop: '40px' }}>
                <FontAwesomeIcon className="iconExample" icon={faHandshake} size="lg" color="#BC1224" style={{ padding: 0, marginRight: '10px' }} />
                {i18n.t('tutorial.whoCouldBeTheSender.customers')}
              </h3>
              <h3 style={{ maxWidth: '95%', marginTop: '15px' }}>
                <FontAwesomeIcon className="iconExample" icon={faUserTie} size="lg" color="#BC1224" style={{ padding: 0, marginRight: '10px' }} />
                {i18n.t('tutorial.whoCouldBeTheSender.suppliers')}
              </h3>
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center', marginBottom: '30px' }}>
              <i>{i18n.t('tutorial.whoCouldBeTheSender.examples')}</i>
            </div>
            <div dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.whoCouldBeTheSender.case2Example') }} />
          </div>
          <div className="slide-5-column">
            <h3>{i18n.t('tutorial.whoCouldBeTheSender.case3')}</h3>
            <div
              className="circle"
              style={{
                boxSizing: 'content-box', marginTop: '20px', width: '220px',
              }}
            >
              <h3 style={{ maxWidth: '95%', marginTop: '20px' }}>
                <FontAwesomeIcon className="iconExample" icon={faUserFriends} size="lg" color="#BC1224" style={{ padding: 0, marginRight: '10px' }} />
                {i18n.t('tutorial.whoCouldBeTheSender.teamMembers')}
              </h3>
              <h3 style={{ maxWidth: '100%', marginTop: '15px', fontSize: '18px' }}>
                <FontAwesomeIcon className="iconExample" icon={faHandHoldingUsd} size="lg" color="#BC1224" style={{ padding: 0, marginRight: '10px' }} />
                {i18n.t('tutorial.whoCouldBeTheSender.financeManager')}
              </h3>
              <h3 style={{ maxWidth: '95%', marginTop: '15px' }}>
                <FontAwesomeIcon className="iconExample" icon={faUserTie} size="lg" color="#BC1224" style={{ padding: 0, marginRight: '10px' }} />
                {i18n.t('tutorial.whoCouldBeTheSender.ceo')}
              </h3>
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center', marginBottom: '30px' }}>
              <i>{i18n.t('tutorial.whoCouldBeTheSender.examples')}</i>
            </div>
            <div dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.whoCouldBeTheSender.case3Example') }} />
          </div>
        </div>
      </div>
    </div>
  );
};

const Slide6 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.whatAreTheyAfter')}</h1>
      <div className="flexRowCenter" style={{ justifyContent: 'space-around', marginTop: '50px', marginBottom: '20px' }}>
        <div style={{ maxWidth: '250px', width: '100%' }}>
          <div style={{
            backgroundColor: '#BC1224',
            color: 'white',
            width: '100px',
            height: '100px',
            borderRadius: '100px',
            textAlign: 'center',
            paddingTop: '20px',
            fontSize: '48px',
            margin: '0 auto',
            marginBottom: '30px',
          }}
          >
            <FontAwesomeIcon icon={faDollarSign} />
          </div>
          <h3 style={{ textAlign: 'center' }}>{i18n.t('tutorial.whatAreTheyAfterT1')}</h3>
          <div style={{ width: '248px', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.whatAreTheyAfterP1') }} />
        </div>
        <div style={{ maxWidth: '250px', width: '100%' }}>
          <div style={{
            backgroundColor: '#BC1224',
            color: 'white',
            width: '100px',
            height: '100px',
            borderRadius: '100px',
            textAlign: 'center',
            paddingTop: '20px',
            fontSize: '48px',
            margin: '0 auto',
            marginBottom: '30px',
          }}
          >
            <FontAwesomeIcon icon={faFolderOpen} />
          </div>
          <h3 style={{ textAlign: 'center' }}>{i18n.t('tutorial.whatAreTheyAfterT2')}</h3>
          <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.whatAreTheyAfterP2') }} />
        </div>
        {' '}
        <div style={{ maxWidth: '250px', width: '100%' }}>
          <div style={{
            backgroundColor: '#BC1224',
            color: 'white',
            width: '100px',
            height: '100px',
            borderRadius: '100px',
            textAlign: 'center',
            paddingTop: '20px',
            fontSize: '48px',
            margin: '0 auto',
            marginBottom: '30px',
          }}
          >
            <FontAwesomeIcon icon={faShieldVirus} />
          </div>
          <h3 style={{ textAlign: 'center' }}>{i18n.t('tutorial.whatAreTheyAfterT3')}</h3>
          <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.whatAreTheyAfterP3') }} />
        </div>
      </div>
    </div>
  );
};

const Slide7 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1 style={{ maxWidth: '750px' }}>{i18n.t('tutorial.formsOfPhishingAttacks1')}</h1>
      <div className="wraps">
        <div className="text" style={{ width: '65%', display: 'flex', flexDirection: 'column' }}>
          <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.formsOfPhishingAttacks1P1') }} />
          <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.formsOfPhishingAttacks1P2') }} />
          <p style={{ maxWidth: '450px' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.formsOfPhishingAttacks1P3') }} />
          <div style={{ display: 'flex' }}>
            <p dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.formsOfPhishingAttacks1P4') }} />
            <FontAwesomeIcon icon={faPhone} style={{ color: '#BC1224', marginTop: '1rem', marginLeft: '20px' }} size="3x" />
          </div>
        </div>
        <img src={i18n.getLanguage() === 'es' ? slide7ES : slide7} className="phishingImage" alt="phishing" />
      </div>
    </div>
  );
};

const Slide8 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1 style={{ maxWidth: '750px' }}>{i18n.t('tutorial.formsOfPhishingAttacks2')}</h1>
      <div className="wraps">
        <div className="text" style={{ width: '65%', display: 'flex', flexDirection: 'column' }}>
          <p style={{ maxWidth: '500px' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.formsOfPhishingAttacks2P1') }} />
          <p style={{ maxWidth: '500px' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.formsOfPhishingAttacks2P2') }} />
          <p style={{ maxWidth: '500px' }} dangerouslySetInnerHTML={{ __html: i18n.t('tutorial.formsOfPhishingAttacks2P3') }} />
        </div>
        <img src={slide8} className="phishingImage" alt="phishing" />
      </div>
    </div>
  );
};

const Slide9 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.preventPhishingAttacks')}</h1>
      <p>{i18n.t('tutorial.preventPhishingAttacksP1')}</p>
      <p>{i18n.t('tutorial.preventPhishingAttacksP2')}</p>
      <div className="flexRow" style={{ marginTop: '40px', marginLeft: '0px' }}>
        <div className="rules">
          <h3 className="number-inside">A</h3>
        </div>
        <div className="textHolder">
          <h2 style={{ marginTop: '0px', marginBottom: '0px' }}>{i18n.t('tutorial.preventPhishingAttacksStep1')}</h2>
        </div>
      </div>
      <div className="flexRow" style={{ marginTop: '20px', marginLeft: '0px' }}>
        <div className="rules">
          <h3 className="number-inside">B</h3>
        </div>
        <div className="textHolder">
          <h2 style={{ marginTop: '0px', marginBottom: '0px' }}>{i18n.t('tutorial.preventPhishingAttacksStep2')}</h2>
        </div>
      </div>
    </div>
  );
};

const Slide10 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <div className="flexRow">
        <div className="rules" style={{ borderColor: '#BC1224' }}>
          <h2 style={{ color: '#BC1224' }}>A</h2>
        </div>
        <h1>{i18n.t('tutorial.thinkBeforeClick')}</h1>
      </div>
      <div className="wraps">
        <div className="text">
          <p>
            {i18n.t('tutorial.thinkBeforeClickP1')}
          </p>
          <p>
            {i18n.t('tutorial.thinkBeforeClickP2')}
          </p>
          <ul>
            <li><p>{i18n.t('tutorial.thinkBeforeClickQ1')}</p></li>
            <li><p>{i18n.t('tutorial.thinkBeforeClickQ2')}</p></li>
            <li><p>{i18n.t('tutorial.thinkBeforeClickQ3')}</p></li>
          </ul>
          <p>
            {i18n.t('tutorial.thinkBeforeClickP3')}
          </p>
        </div>
        <img src={phishingImage} className="phishingImage" alt="phishing" />
      </div>
    </div>
  );
};

const Slide11 = () => {
  const i18n = useTranslation();
  let phis = phishingSignsEN;
  if (i18n.getLanguage() === 'es') phis = phishingSignsES;
  return (
    <div className="tutorialContent">
      <div className="flexRow">
        <h1>{i18n.t('tutorial.telltaleSigns')}</h1>
      </div>
      <p>
        {i18n.t('tutorial.telltaleSignsP1')}
      </p>
      <img src={phis} className="phishingSignsImage" alt="phishing" />
    </div>
  );
};

const Slide12 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <div className="flexRow">
        <h1>{i18n.t('tutorial.contactSender')}</h1>
      </div>
      <div className="wraps">
        <div className="text">
          <p>
            {i18n.t('tutorial.contactSenderP1')}
          </p>
          <p>
            {i18n.t('tutorial.contactSenderP2')}
          </p>
          <p>
            {i18n.t('tutorial.contactSenderP3')}
          </p>
        </div>
        <img src={phone} className="phone" alt="confirm with sender" />
      </div>
    </div>
  );
};

const Slide13 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <div className="flexRow">
        <div className="rules" style={{ borderColor: '#BC1224' }}>
          <h2 style={{ color: '#BC1224' }}>B</h2>
        </div>
        <h1>{i18n.t('tutorial.beVigilantWithPayments')}</h1>
      </div>
      <div className="wraps">
        <div className="text">
          <p>
            {i18n.t('tutorial.beVigilantWithPaymentsP1')}
          </p>
          <p>
            {i18n.t('tutorial.beVigilantWithPaymentsP2')}
          </p>
          <div style={{
            textAlign: 'left', color: '#ea3535', fontSize: '28px', marginTop: '40px', fontWeight: 700,
          }}
          >
            {i18n.t('tutorial.beVigilantWithPaymentsP3')}
          </div>
        </div>
        <img src={slide13} className="phishingImage" alt="phishing" />
      </div>

    </div>
  );
};

const Slide14 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <div className="flexRow">
        <h1>{i18n.t('tutorial.howToVerifyDetails')}</h1>
      </div>
      <div className="wraps">
        <div className="text">
          <p style={{ display: 'flex' }}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                marginTop: '10px', fontSize: '50px', color: '#BC1224', marginRight: '10px',
              }}
            />
            {i18n.t('tutorial.howToVerifyDetailsP1')}
          </p>
          <p style={{ display: 'flex' }}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                marginTop: '10px', fontSize: '50px', color: '#BC1224', marginRight: '10px',
              }}
            />
            {i18n.t('tutorial.howToVerifyDetailsP2')}
          </p>
          {' '}
          <p style={{ display: 'flex' }}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                marginTop: '10px', fontSize: '50px', color: '#BC1224', marginRight: '10px',
              }}
            />
            {i18n.t('tutorial.howToVerifyDetailsP3')}
          </p>
        </div>
        <img src={slide14} className="phishingImage" alt="phishing" />
      </div>

    </div>
  );
};

const Slide15 = () => {
  const i18n = useTranslation();
  return (
    <div className="tutorialContent">
      <h1>{i18n.t('tutorial.phishingEmail')}</h1>
      <p>{i18n.t('tutorial.phishingEmailP1')}</p>
      <div className="flexRowCenter" style={{ marginTop: '40px', marginBottom: '40px' }}>
        <div className="slide-15-circle" style={{ boxSizing: 'content-box' }}>
          <FontAwesomeIcon className="iconExample" icon={faBan} color="#BC1224" size="2x" />
          <h3 style={{ maxWidth: '150px' }}>{i18n.t('tutorial.phishingEmailNote1')}</h3>
        </div>
        <FontAwesomeIcon className="iconStep2" icon={faChevronRight} color="#BC1224" size="2x" />
        <div className="slide-15-circle" style={{ boxSizing: 'content-box' }}>
          <FontAwesomeIcon className="iconExample" icon={faTrash} color="#BC1224" size="2x" />
          <h3 style={{ maxWidth: '150px' }}>{i18n.t('tutorial.phishingEmailNote2')}</h3>
        </div>
        <FontAwesomeIcon className="iconStep2" icon={faChevronRight} color="#BC1224" size="2x" />
        <div className="slide-15-circle" style={{ boxSizing: 'content-box' }}>
          <FontAwesomeIcon className="iconExample" icon={faFlag} color="#BC1224" size="2x" />
          <h3 style={{ maxWidth: '150px' }}>{i18n.t('tutorial.phishingEmailNote3')}</h3>
        </div>
      </div>
      <p>{i18n.t('tutorial.phishingEmailP2')}</p>
    </div>
  );
};

const slides = {
  step1: <Slide1 />,
  step2: <Slide2 />,
  step3: <Slide3 />,
  step4: <Slide4 />,
  step5: <Slide5 />,
  step6: <Slide6 />,
  step7: <Slide7 />,
  step8: <Slide8 />,
  step9: <Slide9 />,
  step10: <Slide10 />,
  step11: <Slide11 />,
  step12: <Slide12 />,
  step13: <Slide13 />,
  step14: <Slide14 />,
  step15: <Slide15 />,
};

export default slides;
