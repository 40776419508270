import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import { useTranslation } from '../../i18n';

const FalseWrapper = styled.div`
display: inline-block;
color: #ea4435;
padding: 0 20px;
vertical-align: middle;
font-size: 20px;
font-weight: 600;
`;

const False = () => {
  const i18n = useTranslation();
  return (
    <FalseWrapper>
      <FontAwesomeIcon icon={faTimesCircle} />
      &nbsp;&nbsp;
      {i18n.t('quiz.missed')}
    </FalseWrapper>
  );
};
export default False;
