import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';


import { useTranslation } from '../i18n';
import { UserContext } from '../user';

import './App.css';

const axios = require('axios');

const qs = (function (a) {
  if (a == '') return {};
  const b = {};
  for (let i = 0; i < a.length; ++i) {
    const p = a[i].split('=', 2);
    if (p.length == 1) { b[p[0]] = ''; } else { b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' ')); }
  }
  return b;
}(window.location.search.substr(1).split('&')));

const App = () => {
  const [user, setUser] = useState({ firstName: '', companyName: 'Your company' });
  const i18n = useTranslation();
  const [codeLang, setCodeLang] = useState();
  const userFromContext = React.useContext(UserContext);
  const { u, p } = useParams();

  useEffect(() => {
    axios.post('/userinfo', { userId: u, gophishId: p })
      .then((userinfo) => {
        userFromContext.setParams(userinfo.data);
        setUser(userFromContext);
      })
      .catch((error) => {
        console.error('Failed to save: ', error);
      });
    const { lang } = qs;
    i18n.changeLanguage(lang);
    setCodeLang(lang);
  }, []);


  // TODO use training id to show the right training
  // if (!user.firstName || !trainingId) {
  //   return 'This is not a valid link to a training.';
  // }

  return (

    <div className="tutorialWrapper">

      <div className="box" style={{ width: '100%', maxWidth: '900px' }}>
        <div className="phishingStart">
          <img 
            style={{
              width: '250px', maxWidth: '250px', height: 'auto', margin: 'auto' 
              }} 
              src="https://storage.googleapis.com/cyberguardian-assets/Training/Phishing%20Training/Assets/Images/logo.svg"
              />
          <br/>
          <br />
          <h1>{i18n.t('welcome.title', { user: user.firstName })}</h1>
          <p />
          <Link to={`/${i18n.getLanguage()}/phishing/tutorial`} style={{ textDecoration: 'none' }}>
            <button type="button" className="startButton">

              <FontAwesomeIcon icon={faPlay} color="white" size="1x" style={{ marginRight: '20px' }} />
              {i18n.t('welcome.startTraining')}
            </button>
          </Link>

        </div>
        <div
          className="companyName"
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%,0)',
          }}
        >
          <p>{i18n.t('welcome.onBehalf')}</p>
          <h2>{ user.companyName }</h2>
        </div>
      </div>

    </div>
  );
};


export default App;
