import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from '../../../i18n';
import FoundElement from '../FoundElement';
import GoogleIcon from '../../../img/Google@2x.png';


const Question1Wrapper = styled.div`
width: 100%;
max-width: 956px;
padding: 33px;
border-radius: 7px;
border: 1px solid #DCDCDC;
margin: 0 auto;
font-family: 'Poppins', sans-serif;
`;

const Question1Title = styled.h2`
color: #555555;
font-size: 26px;
font-weight: 700;
margin-top: 0;
margin-bottom: 10px;
`;

const EmailUserIcon = styled.div`
display: inline-block;
height: 61px;
width: 61px;
border-radius: 50%;
background-color: #E6E6E6;
color: #6E6E6E;
margin-right: 18px;
font-size: 18px;
padding: 17px 22px;
`;

const EmailHeaderDetails = styled.div`
vertical-align: middle;
display: inline-block;
font-size: 16px;
color: #555555;

& span {
  display: inline-block;
  line-height: 24px;
  width:39px;
  text-align: right;
  font-size: 15px;
  color: #7B7B7B;
}
`;

const EmailContent = styled.div`
width: 100%;
margin-top: 20px;
border-radius: 3px;
border: 1px solid #EBEBEB;
background-color: white;
overflow:hidden;
`;

const EmailContentHeader = styled.div`
display:flex;
padding: 17px 15%;
background-color: #F9F9F9;
justify-content: space-between;

& img {
 width: 96px;
 margin: 5px 0;
}

& div {
  display:inline-block;
  border-radius: 50%;
  background-color: #bc1224;
  color: white;
  width: 52px;
  height: 52px;
  font-size: 20px;
  text-align: center;
  padding: 12px 0;
}
`;

const EmailContentBody = styled.div`
padding: 33px;
`;

const Question1 = (props) => {
  const i18n = useTranslation();
  const {
    stats, setStats, currentQuestion, submiting,
  } = props;
  const [clicked, setClicked] = useState({});

  useEffect(() => {
    const firstTime = {
      found: { }, clicks: 1, total: 3, type: 'email',
    };
    stats.push(firstTime);
    const newStats = [...stats];
    setStats(newStats);
  }, []);

  const handleClick = (e, el) => {
    if (submiting) return;
    e.stopPropagation();
    if (el) {
      if (!stats[currentQuestion]) {
        const firstTime = {
          found: { [el]: true }, clicks: 1, total: 3, type: 'email',
        };
        stats.push(firstTime);
        const newStats = [...stats];
        setStats(newStats);
        setClicked({ [el]: true });
      } else {
        const newStats = [...stats];
        newStats[currentQuestion] = {
          ...newStats[currentQuestion],
          clicks: stats[currentQuestion].clicks + 1,
          found: { ...stats[currentQuestion].found, [el]: true },
          total: 3,
          type: 'email',
        };
        setStats(newStats);
        setClicked({ ...newStats[currentQuestion].found });
      }
    } else if (!stats[currentQuestion]) {
      const firstTime = { clicks: 1, total: 3, type: 'email' };
      stats.push(firstTime);
      const newStats = [...stats];
      setStats(newStats);
    } else {
      const newStats = [...stats];
      newStats[currentQuestion] = {
        ...newStats[currentQuestion],
        clicks: stats[currentQuestion].clicks + 1,
        total: 3,
        type: 'email',
      };
      setStats(newStats);
    }
  };

  return (
    <Question1Wrapper onClick={(e) => handleClick(e)}>
      <Question1Title>
        {i18n.t('quiz.q1.emailTitle')}
      </Question1Title>
      <FoundElement submiting={submiting} name="one" handleClick={(e) => handleClick(e, 'one')} clicked={clicked.one}>
        <EmailUserIcon>
          <FontAwesomeIcon icon={faUser} />
        </EmailUserIcon>
        <EmailHeaderDetails>
          <span>
            {i18n.t('quiz.q1.from')}
            :
          </span>
          &nbsp;&nbsp;
          admin@google-accounts.com
          <br />
          <span>
            {i18n.t('quiz.q1.to')}
            :
          </span>
          &nbsp;&nbsp;
          you@yourcompany.com
        </EmailHeaderDetails>
      </FoundElement>
      <EmailContent>
        <EmailContentHeader>
          <img src={GoogleIcon} alt="Google Icon" />
          <div>
            <FontAwesomeIcon icon={faUser} />
          </div>
        </EmailContentHeader>
        <EmailContentBody>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{
              display: 'block', margin: '5px auto', color: '#EA4435', fontSize: '36px',
            }}
          />
          <div style={{ marginLeft: 'calc(50% - 225px)' }}>
            <FoundElement submiting={submiting} handleClick={(e) => handleClick(e, 'two')} clicked={clicked.two}>
              <div style={{
                color: '#545454',
                fontSize: '28px',
                fontWeight: '600',
                width: '100%',
                maxWidth: '450px',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
              >
                {i18n.t('quiz.q1.emailContent')}
              </div>
            </FoundElement>
          </div>
          <div style={{ color: '#ABABAB', fontSize: '20px', textAlign: 'center' }}>
            your@yourcompany.com
          </div>
          <div style={{
            fontSize: '14px',
            maxWidth: '500px',
            width: '100%',
            margin: '0 auto',
            borderTop: '1px solid #EAEAEA',
            padding: '20px 0',
            color: '#7F7F7F',
            marginTop: '26px',
            textAlign: 'center',
          }}
          >
            {i18n.t('quiz.q1.note')}
          </div>
          <div style={{ marginLeft: 'calc(50% - 100px)' }}>
            <FoundElement submiting={submiting} handleClick={(e) => handleClick(e, 'three')} clicked={clicked.three}>
              <a
                href="http://fake-domain.com/login"
                style={{
                  textDecoration: 'none',
                  backgroundColor: '#FF8181',
                  padding: '11px 20px',
                  borderRadius: '3px',
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: 600,
                  margin: '10px 0',
                  display: 'inline-block',
                }}
                onClick={(e) => e.preventDefault()}
              >
                {i18n.t('quiz.q1.checkActivity')}
              </a>
            </FoundElement>
          </div>
        </EmailContentBody>
      </EmailContent>
    </Question1Wrapper>
  );
};

export default Question1;
