import ReactGA from 'react-ga';
import Swal from 'sweetalert2';
import { i18n } from './i18n';

export const TrackButton = (action, label) => {
  ReactGA.event({
    category: 'Button',
    action,
    label,
  });
};

export const TrackError = (action, label) => {
  ReactGA.event({
    category: 'Error',
    action,
    label,
  });
};

export const TrackInfo = (action, label) => {
  ReactGA.event({
    category: 'Info',
    action,
    label,
  });
};

// export const ErrorMessage = (message, label) => {
//   Swal.fire(i18n.t('common.error'), message, 'error');
//   TrackError(message, label);
// };

export const ErrorKey = (i18nKey, label, i18nOptions) => {
  Swal.fire(i18n.t('common.error'), i18n.t(i18nKey, i18nOptions), 'error');
  TrackError(i18nKey, label);
};

export const WarningKey = (i18nKey, label, i18nOptions) => {
  Swal.fire(i18n.t('common.warning'), i18n.t(i18nKey, i18nOptions), 'warning');
  TrackError(i18nKey, label);
};

export const InfoKey = (i18nKey, label, i18nOptions) => {
  Swal.fire(i18n.t(i18nKey, i18nOptions));
  TrackInfo(i18nKey, label);
};

export const SuccessKey = (i18nKey, label, i18nOptions) => {
  Swal.fire('', i18n.t(i18nKey, i18nOptions), 'success');
  TrackInfo(i18nKey, label);
};

export const addClickListener = () => {
  document.getElementById('root').addEventListener('click', (event) => {
    if (['checkbox', 'radio', 'text', 'textarea'].indexOf(event.target.type) === -1) {
      const trackSlug = event.target.dataset.slug;
      if (trackSlug) {
        TrackButton(trackSlug, trackSlug);
      }
    }
  });
};

export const addInputChangeListener = () => {
  document.getElementById('root').addEventListener('change', (event) => {
    if (['checkbox', 'radio'].indexOf(event.target.type) > -1) {
      const trackSlug = event.target.dataset.slug;
      if (trackSlug) {
        let val = true;
        if (event.target.type === 'checkbox') {
          if (!event.target.checked) val = false;
        }
        TrackInfo(val, trackSlug);
      }
    }
  });
};
