import React from 'react';
import styled from '@emotion/styled';
import Correct from './Correct';
import False from './False';

const EmailHeader = styled.div`
padding: 11px 20px;
${(props) => ((props.clicked || props.submiting) ? 'box-shadow: 0 2px 13px 2px rgba(0, 0, 0, 0.15);' : '')}
display: inline-block;
border-radius: 4px;
margin-left: -20px;
`;

const FoundElement = (props) => {
  const {
    handleClick, clicked, children, submiting,
  } = props;

  return (
    <EmailHeader submiting={submiting} onClick={handleClick} clicked={clicked}>
      {children}
      {clicked && <Correct />}
      {(!clicked && submiting) && <False />}
    </EmailHeader>
  );
};

export default FoundElement;
