import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import slides from './Slides';
import { useTranslation } from '../../i18n';
import { UserContext } from '../../user';

const axios = require('axios');


const useStyles = makeStyles({
  root: {
    maxWidth: '1000px',
    width: 'auto',
    borderBottomRightRadius: '6px',
    borderBottomLeftRadius: '6px',
    background: 'white',
  },

});

function getStepContent(step) {
  switch (step) {
    case 0:
      return slides.step1;
    case 1:
      return (
        <div>
          {slides.step2}
        </div>
      );
    case 2:
      return (
        <div>
          {slides.step3}
        </div>
      );

    case 3:
      return (
        <div>
          {slides.step4}
        </div>
      );

    case 4:
      return (
        <div>
          {slides.step5}
        </div>
      );

    case 5:
      return (
        <div>
          {slides.step6}
        </div>
      );

    case 6:
      return (
        <div>
          {slides.step7}
        </div>
      );

    case 7:
      return (
        <div>
          {slides.step8}
        </div>
      );

    case 8:
      return (
        <div>
          {slides.step9}
        </div>
      );

    case 9:
      return (
        <div>
          {slides.step10}
        </div>
      );

    case 10:
      return (
        <div>
          {slides.step11}
        </div>
      );
    case 11:
      return (
        <div>
          {slides.step12}
        </div>
      );

    case 12:
      return (
        <div>
          {slides.step13}
        </div>
      );

    case 13:
      return (
        <div>
          {slides.step14}
        </div>
      );

    case 14:
      return (
        <div>
          {slides.step15}
        </div>
      );

    default:
      return 'Unknown step';
  }
}

export default function Tutorial() {
  const history = useNavigate();
  const i18n = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const user = React.useContext(UserContext);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (activeStep === 0) {
    const data = {
      userId: user.userId,
      clientId: user.clientId,
      trainingId: 'phishing',
      status: 'opened',
    };

    axios.post('/training', data)
      .catch((error) => {
        console.error('Failed to save: ', error);
        return false;
      });
  }

  return (

    <div className="tutorialWrapper">
      <div className="box">
        <div className="tutorialOne">
          {getStepContent(activeStep)}
          <MobileStepper
            variant="dots"
            steps={15}
            position="static"
            activeStep={activeStep}
            className={classes.root}
            nextButton={(
              <Button size="large" onClick={activeStep === 14 ? (event) => history(`/${i18n.getLanguage()}/phishing/quiz`) : handleNext}>
                {activeStep === 14 ? i18n.t('finish') : i18n.t('next')}
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
              )}
            backButton={(
              <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                {i18n.t('back')}
              </Button>
              )}
          />

        </div>
      </div>
    </div>
  );
}
