import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { usePopper } from 'react-popper';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

const PopperOnHover:React.FC<{text:any, children: any}> = ({ text, children }) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const [arrowElement, setArrowElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [{ name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [-80, 10] } }],
  });
  const [showPopper, setShowPopper] = useState(false);

  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
      }}
      ref={setReferenceElement}
      onMouseOver={() => setShowPopper(true)}
      onMouseOut={() => setShowPopper(false)}
    >
      {children}
      { showPopper && (
        <PopperContainer
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {text}
          <div ref={setArrowElement} style={styles.arrow} className="arrow" />
        </PopperContainer>
      )}
    </div>
  );
};

export default PopperOnHover;

const PopperContainer = styled.div`
  z-index:10000;
  width: 247px;
  background: white;
  box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.2);
  padding: 10px;
  border-radius: 5px;

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    left:0px;

    &:after {
      content: " ";
      position: absolute;
      top: 0; // we account for the PopperContainer padding
      left: 0;
      transform: rotate(-225deg);
      width: 10px;
      height: 10px;
      background-color: white;
      //box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  
  &[data-popper-placement^='right'] > .arrow {
    :after {
      left: -5px;
      //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  &[data-popper-placement^='left'] > .arrow {
    right: 0;
    left:auto;
    :after {
      left:auto;
      right:-5px;
    }
  }
  &[data-popper-placement^='top'] > .arrow {
    bottom: -5px;
    :after {
      bottom: -5px;
      //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -2px;
    :after {
      top: -2px;
    }
  }
`;
