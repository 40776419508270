import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import ReactGA from 'react-ga';

import rootReducer from './reducers';

import {
  SIGN_OUT,
  ADD_EMAIL_RESULTS,
  SHOW_POP_UP,
  UPDATE_USER,
  REMOVE_EXPLICIT_EMAIL_RESULTS,
  REMOVE_ALL_EMAILS_RESULTS,
  SET_DOMAIN_SCAN_IN_PROGESS,
  REMOVE_EXPLICIT_SUPPLIER_RESULTS,
  FINISH_TUTORIAL,
} from './actions/types';

const trackingMiddleware = (store) => (next) => (action) => {
  // Would be for tracking page views
  // if (action.type === '@@router/LOCATION_CHANGE') {
  //   const nextPage = `${action.payload.pathname}${action.payload.search}`;
  //   ReactGA.event(nextPage, store.getState());
  // }

  switch (action.type) {
    case SIGN_OUT:
      //    case ADD_EMAIL_RESULTS:
    case UPDATE_USER:
    case REMOVE_EXPLICIT_EMAIL_RESULTS:
    case REMOVE_ALL_EMAILS_RESULTS:
      //    case SET_DOMAIN_SCAN_IN_PROGESS:
    case REMOVE_EXPLICIT_SUPPLIER_RESULTS:
    case FINISH_TUTORIAL:
      ReactGA.event({
        category: 'redux',
        action: action.type,
      });
      break;
    case SHOW_POP_UP:
      ReactGA.event({
        category: 'redux',
        action: action.type,
        label: action.payload.popUpCode,
      });
      break;
    default:
  }

  return next(action);
};

export default function configureStore(initialState, history) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      // reactReduxFirebase(firebase, rrfConfig), // pass in firebase instance instead of config
      // reduxFirestore(firebase) // <- needed if using firestore
      applyMiddleware(reduxThunk), // to add other middleware
      applyMiddleware(trackingMiddleware), // tracking Redux events
    ),
  );
  return store;
}
